/* eslint-disable no-undef */
import imgFile from 'assets/file-image.svg';
import excelFile from 'assets/file-excel.svg';
import pdfFile from 'assets/file-pdf.svg';
import docFile from 'assets/file-doc.svg';

import { Box, Typography } from '@mui/material';
import { capitalize } from 'lodash';
import { ToWords } from 'to-words';
import moment from 'moment';
import { greyText } from 'styles/mui/themeVariables';
import { ROWS_PER_PAGE } from './constants';

const blankFile = 'assets/file-pdf.svg';
export const getSorting = (order, orderBy) => {
  if (order === 'asc') {
    return (a, b) => {
      if (Number(a[orderBy]) < Number(b[orderBy])) {
        return -1;
      }
      if (Number(a[orderBy]) > Number(b[orderBy])) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (Number(a[orderBy]) > Number(b[orderBy])) {
      return -1;
    }
    if (Number(a[orderBy]) < Number(b[orderBy])) {
      return 1;
    }
    return 0;
  };
};
export const transformToFormData = values => {
  const formData = new FormData();
  Object.entries(values).forEach(([key]) => {
    if (
      key !== 'report_images' &&
      key !== 'order' &&
      key !== 'email' &&
      key !== 'image' &&
      key !== 'booking' &&
      key !== 'city' &&
      key !== 'reporter' &&
      key !== 'request_type'
    ) {
      formData.append(`${key}`, values[key]);
    }
  });

  if (values?.email) {
    formData.append('email', values?.email);
  }
  if (values?.image && typeof values?.image !== 'string') {
    formData.append('image', values?.image, values?.image?.name);
  }

  if (values?.booking) {
    formData.append('booking', values?.booking);
  }

  if (values?.reporter) {
    formData.append('reporter', values?.reporter);
  }

  if (values?.reporter) {
    formData.append('request_type', values?.request_type);
  }

  // Append Images array
  if (values?.report_images) {
    values?.report_images?.forEach((image, index) => {
      formData.append(`report_images[${index}]file`, image);
    });
  }

  const formDataObject = {};
  formData.forEach((value, key) => {
    formDataObject[key] = value;
  });

  return formData;
};
export const getDateSorting = (order, orderBy) => {
  if (order === 'asc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
};
export const setIconByFileType = type => {
  if (type.includes('pdf')) return pdfFile;
  if (type.includes('css')) return cssFile;
  if (
    type.includes('image') ||
    type.includes('png') ||
    type.includes('jpg') ||
    type.includes('jpeg') ||
    type.includes('svg') ||
    type.includes('jfif')
  ) {
    return imgFile;
  }
  if (type.includes('ai')) return aiFile;
  if (type.includes('sql')) return sqlFile;
  if (
    type.includes('xls') ||
    type.includes('xlsx') ||
    type.includes('csv') ||
    type.includes('ms-excel') ||
    type.includes('spreadsheet')
  ) {
    return excelFile;
  }
  if (type.includes('doc')) return docFile;

  return blankFile;
};
export const splitLongText = value => (value ? value.split(' ').slice(0, 5).join(' ').concat('...') : '');
export const truncateText = (text, maxLength) => {
  if (typeof text !== 'string') {
    return '';
  }

  if (text.length <= maxLength) {
    return text;
  }

  return `${text.slice(0, maxLength)}...`;
};
export const checkIsMessageSentByMe = owner => owner === 'staff' || owner === 'admin';
export const transformOptions = array => array?.map(item => ({
  ...item.response,
  last_message_time: item.response.last_message_time || item.response.room_created_at,
}));
export const formatName = (firstName, lastName, userName) => (!!firstName && !!lastName ? `${firstName} ${lastName[0]}` : userName);
export const findUnreadNotificationCount = notifications => {
  if (notifications?.length > 0) return notifications[0]?.unread_notification_count;
  return 0;
};
export const changeTabFavIcon = (companyInfo = {}) => {
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = companyInfo.fav_icon;
};
export const bookingIdStringJoin = arr => {
  if (typeof arr === 'string') {
    return arr; // return the single element as a string
  }
  return arr.join(' , '); // join all elements with ' , '
};
export const getParsedPrice = (price = 0.0) => {
  if (price) {
    return `${price?.toFixed(0)}`;
  }

  return 'On Request';
};
export const getMenuOptions = status => {
  if (status === 'CONFIRMED') {
    return [{ value: 'cancel', label: 'Cancel', selectedValue: 'Cancel' }];
  }
  if (status === 'PENDING') {
    return [
      { value: 'confirmed', label: 'Confirm', selectedValue: 'Confirmed' },
      { value: 'declined', label: 'Decline', selectedValue: 'Declined' },
    ];
  }
  return [];
};
export const formatCancelationDate = dateString => moment(dateString).format('dddd DD MM, YYYY hh:mm A');
export const formatAfterCancelationDate = dateString => moment(dateString).add(1, 'minute').format('dddd DD MM, YYYY hh:mm A');

export const changeDefaultMode = modifiedPaymentModes => ({
  type: CHANGE_DEFAULT_MODE,
  payload: modifiedPaymentModes,
});
export const toTitleCase = str => {
  if (!str) return str;
  return str
    .split('_') // Split by underscore if present
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter, lowercase the rest
    .join(' '); // Join with spaces
};
export const toLowerCase = text => text.toLowerCase();
export function formatAmount(amount = 0) {
  try {
    const formattedAmount = amount.toLocaleString('en-US', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    if (formattedAmount) return formattedAmount;
    return amount;
  } catch (error) {
    return '0.00';
  }
}

export function formatNumber(number = 0) {
  const convertedNum = Number(number).toFixed(2);
  return Number(convertedNum);
}

export const toWords = new ToWords({
  localeCode: 'en-US',
  converterOptions: {
    currency: false,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
  },
});

export const handleGetAmountInWords = amount => {
  const amountToWords = toWords.convert(amount)?.toLowerCase();
  const capitalLetter = amountToWords.charAt(0).toUpperCase();
  if (amountToWords?.includes('point')) {
    return `${capitalLetter}${amountToWords
      ?.replace('point', 'and')
      ?.slice(1, amountToWords.length)} fills`;
  }
  return `${capitalLetter}${amountToWords?.slice(1, amountToWords.length)}`;
};

export function formatString(name) {
  let separatedName = [];
  if (name && name.indexOf('_') > -1) separatedName = name.split('_');
  if (name && name.indexOf('-') > -1) separatedName = name.split('-');
  if (name && name.indexOf(' ') > -1) separatedName = name.split(' ');

  if (separatedName.length > 0) {
    const newName = separatedName.map(item => capitalize(item));
    const formattedName = newName.join(' ');
    return formattedName;
  }
  return capitalize(name);
}

export const formatAmountInShortForm = n => {
  if (n >= 1e3 && n < 1e6) return `${(n / 1e3).toFixed(2)}K`;
  if (n >= 1e6 && n < 1e9) return `${(n / 1e6).toFixed(2)}M`;
  if (n >= 1e9 && n < 1e12) return `${(n / 1e9).toFixed(2)}B`;
  if (n >= 1e12) return `${(n / 1e12).toFixed(2)}T`;
  return n;
};

export const abbreviateAmount = amount => {
  if (amount < 0) {
    return `-${formatAmountInShortForm(-1 * amount)}`;
  }
  return formatAmountInShortForm(amount);
};

export const getSpaces = value => {
  let spaces = '';
  for (let i = 0; i <= value * 5; i += 1) {
    spaces += ' ';
  }
  return spaces;
};
export function stableSort(array, comparator) {
  if (array?.length > 0) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  return [];
}

// General
export const getRowsPerPage = search => {
  const query = {};
  const limit = search.get('limit') || '';
  const offset = search.get('offset') || '';
  if (limit) {
    query.limit = limit;
  } else {
    query.limit = ROWS_PER_PAGE;
  }
  if (offset && offset !== '0') {
    query.offset = offset;
  } else {
    query.offset = 0;
  }
  return query;
};
export const getsearchQueryOffsetAndLimitParams = location => {
  let query = {};
  const search = new URLSearchParams(location.search);
  if (!search) return query;
  const limit = getRowsPerPage(search);
  query = { ...limit };
  return query;
};
export function getSearchParamsList(searchParams) {
  return Object.fromEntries(searchParams);
}

export const changeQueryParameters = (query, queryObject) => {
  Object.entries(queryObject).forEach(([key, value]) => {
    if (value) {
      query.set(key, value);
    } else {
      query.delete(key);
    }
  });
  return query.toString();
};

export const deleteQueryParameter = (query, queryKey) => {
  query.delete(queryKey);
  return query.toString();
};

export const handleClickItem = (item, searchParams, navigate, pathname) => {
  if (item.value) {
    navigate({
      pathname,
      search: changeQueryParameters(searchParams, { filter: item.value }),
    });
  } else {
    navigate({
      pathname,
      search: deleteQueryParameter(searchParams, 'filter'),
    });
  }
};
export const handleSortClickItem = (item, searchParams, navigate, pathname) => {
  if (item) {
    navigate({
      pathname,
      search: changeQueryParameters(searchParams, item),
    });
  } else {
    navigate({
      pathname,
      search: deleteQueryParameter(searchParams, 'sort_by'),
    });
  }
};

export const getPaginationData = location => {
  const query = new URLSearchParams(location.search);
  const rowsPerPage = query.get('rows_per_page') || '20';
  const page = query.get('page') || '0';

  return { rowsPerPage: +rowsPerPage, page: +page, query };
};

export function getAccountOptions(accounts = [], disbledOptionTitles = []) {
  const groups = new Set(accounts?.map(item => item.account_type.account_type_formatted));

  const typeOptions = [...groups].map(group => ({
    label: group,
    options: accounts
      ?.filter(type => type.account_type.account_type_formatted === group)
      ?.map(item => {
        const option = {
          label: item.account_name,
          value: item.id,
          isDisabled: disbledOptionTitles.includes(item.account_name),
        };
        return option;
      }),
  }));

  return typeOptions;
}

export const convertURLToFile = async url => {
  const fileName = url.substring(url.lastIndexOf('/') + 1);
  const response = await fetch(`${url}?timestamp=${new Date().getTime()}`);
  const blob = await response.blob();
  const file = new File([blob], `${fileName}`, {
    type: blob.type,
  });
  return file;
};

export async function urlToFileBase64(url) {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        const base64data = reader.result.split(',')[1];
        resolve(base64data);
      };
      reader.onerror = reject;
    });
  } catch (error) {
    // console.error('Error:', error);
    return null;
  }
}

export function isValidURL(value) {
  const urlPattern =
    /^(https?:\/\/)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+(:\d+)?)(\/[^\s]*)?$/i;
  return urlPattern.test(value);
}

export const getPaymentModesOptions = optionsArray => (optionsArray
  ? optionsArray?.map(mode => ({
    value: mode.payment_mode_name,
    label: mode.payment_mode_name,
  }))
  : []);

export const getBankAccountsOptions = optionsArray => (optionsArray
  ? optionsArray?.map(account => ({
    value: account.id,
    label: account.bank_account_name,
  }))
  : []);

export const getCurrenciesOptions = optionsArray => (optionsArray
  ? optionsArray?.map(item => ({
    value: item.currency_code,
    label: `${item.currency_code} - ${item.currency_name}`,
  }))
  : []);

export const getCurrenciesOptionsWithId = optionsArray => (optionsArray
  ? optionsArray?.map(item => ({
    value: item.id,
    label: `${item.currency_code} - ${item.currency_name}`,
  }))
  : []);

export const getSalesPersonsOptions = optionsArray => (optionsArray
  ? optionsArray?.map(item => ({
    value: `${item.id}`,
    label: item.sales_person_name,
  }))
  : []);

export const getPaymentTermsOptions = optionsArray => (optionsArray
  ? optionsArray?.map(item => ({
    value: item.term_name,
    label: item.term_name,
  }))
  : []);

export const getPlaceOfSupplyOptions = optionsArray => (optionsArray
  ? optionsArray?.map(item => ({
    value: item.place_name,
    label: item.place_name,
  }))
  : []);

export const getTaxTreatmentsOptions = optionsArray => (optionsArray
  ? optionsArray?.map(item => ({
    value: item.lookup_name,
    label: item.lookup_name,
    description: item.description,
  }))
  : []);

export const getPurOrdersOptions = optionsArray => (optionsArray
  ? optionsArray?.map(item => ({
    value: `${item.id}`,
    label: item.pur_order_formatted_number,
  }))
  : []);

export const getTaxRatesOptions = optionsArray => (optionsArray
  ? optionsArray?.map(rate => ({
    value: `${rate.id}`,
    label: rate.tax_rate_name,
  }))
  : []);

export const getTitleOptions = optionsArray => (optionsArray
  ? optionsArray?.map(title => ({
    value: title.lookup_name,
    label: title.lookup_name,
  }))
  : []);

export const getCountryOptions = optionsArray => (optionsArray
  ? optionsArray?.map(country => ({
    id: country.id,
    value: country.name,
    label: country.name,
  }))
  : []);
export const getStateOptions = optionsArray => (optionsArray
  ? optionsArray?.map(state => ({
    id: state.id,
    value: state.name,
    label: state.name,
  }))
  : []);
export const getCityOptions = optionsArray => (optionsArray
  ? optionsArray?.map(city => ({
    id: city.id,
    value: city.name,
    label: city.name,
  }))
  : []);
export const getInvoiceOptions = optionsArray => (optionsArray
  ? optionsArray?.map(item => ({
    value: `${item.id}`,
    label: `${item.invoice_num}-${item.customer_type_suffix}-${item.invoice_prefix}`,
  }))
  : []);

export const getItemsOptions = optionsArray => (optionsArray
  ? optionsArray
    ?.filter(item => item.is_active)
    .map(item => ({
      value: item.item_name,
      label: item.item_name,
      // isDisabled: getOptionDisability(item, values),
    }))
  : []);

export const getSuppliersOptions = optionsArray => (optionsArray
  ? optionsArray
    ?.filter(supplier => supplier.is_active)
    ?.sort(getSorting('desc', 'supplier_name'))
    ?.map(supplier => ({
      value: `${supplier.id}`,
      label: supplier.supplier_name,
    }))
  : []);

export const getCompaniesProfilesOptions = optionsArray => (optionsArray
  ? optionsArray.map(company => ({
    value: `${company?.id}`,
    label: company?.company_name,
  }))
  : []);

export const getPaymentModeOptions = optionsArray => (optionsArray
  ? optionsArray?.map(mode => ({
    value: mode.payment_mode_name,
    label: mode.payment_mode_name,
  }))
  : []);

export const getSalesAccountsOptions = optionsArray => (optionsArray
  ? optionsArray?.map(salesAccount => ({
    value: `${salesAccount.id}`,
    label: salesAccount.sales_account_name,
  }))
  : []);

export const fileUrlWithTimeStamp = (url = '') => `${url}?timestamp=${new Date().getTime()}`;

export const downloadFileWithURL = (fileURL, fileName = 'document') => {
  fetch(fileUrlWithTimeStamp(fileURL))
    .then(response => response.blob())
    .then(blob => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.target = '_blank';
      link.click();
      URL.revokeObjectURL(url); // Clean up the URL object to free memory
    })
    .catch(() => {
      // Handle error if the fetch request fails
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = fileName;
      link.target = '_blank';
      link.click();
    });
};

export const groupArrayOfObjects = (templist, key) => templist.reduce((rv, x) => {
  // eslint-disable-next-line no-param-reassign
  (rv[x[key]] = rv[x[key]] || []).push(x);
  return rv;
}, {});

/* eslint-disable */
export const formatTaxTreatmentOptions = (option, metaData) =>
  metaData.context === 'menu' ? (
    <div>
      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
        {option.label}
      </Typography>
      <Typography variant="caption">{option.description}</Typography>
    </div>
  ) : (
    option.label
  );

export function yupTimeValidator(value) {
  const format = 'HH:mm:ss';

  if (!value) {
    return false;
  }

  const isValid = moment(value, format, true).isValid();

  if (!isValid) {
    return false;
  }

  return true;
}

export function yupLessTimeValidator(value) {
  const format = 'HH:mm:ss';

  if (!value) {
    return false;
  }
  const currentTime = moment().format('HH:mm:ss');
  const isValid = moment(value, format).isBefore(moment(currentTime, format));
  if (!isValid) {
    return false;
  }

  return true;
}
export const getFlattenArray = (data = [], childrenKey = 'children') => {
  return data.reduce(
    (acc, item) => acc.concat(item, ...getFlattenArray(item[childrenKey] || [])),
    []
  );
};

export const formatDate = (date, format = 'YYYY-MM-DD') =>
  date ? moment(date).format(format) : null;

export const getFormattedTime = time => {
  const formattedTime = moment.duration(time, 'HH:mm:ss');
  return `${
    formattedTime.days() * 24 + formattedTime.hours()
  }h ${formattedTime.minutes()}m`;
};

export const largeTextSlice = (value, limit) => {
  return value?.length > limit ? `${value.slice(0, limit)}...` : value;
};

export function renderLabelData(label, value) {
  return value ? (
    <Box className="d-flex gap-1">
      <Typography variant="body2">{label}:</Typography>
      <Typography variant="body2" sx={{ color: greyText }}>
        {value}
      </Typography>
    </Box>
  ) : null;
}
