import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Button, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { ArrowDownward, ArrowUpward, MenuSharp } from '@mui/icons-material';
import useGetThemeColor from 'customHooks/useGetThemeColor';

function SortMenu({ sortOptions, sortFunc }) {
  const [selected, setSelected] = useState(null);
  const [sortOrder, setSortOrder] = useState('A');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { primary } = useGetThemeColor();

  const handleMenuClick = e => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = value => {
    setSelected(value.value);
    setSortOrder(sortOrder === 'A' ? 'D' : 'A');
    sortFunc({ ordering: sortOrder === 'A' ? `-${value.value}` : value.value });
  };
  return (
    <Box>
      <Tooltip text="Sort Options" position="top" className="mr-3 text-nowrap">
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={handleMenuClick}
        >
          <MenuSharp />
        </Button>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem>
          <Typography sx={{ fontSize: '14px' }} variant="subtitle1" className="fw-bold">
            SORT BY{' '}
          </Typography>
        </MenuItem>
        {sortOptions.map(menu => (
          <MenuItem
            style={{
              backgroundColor: selected === menu.value && primary,
              color: selected === menu.value && '#fff',
            }}
            key={menu.label}
            onClick={() => {
              handleClick(menu);
              handleClose();
            }}
            className="d-flex justify-content-between"
          >
            <Typography sx={{ fontSize: '16px' }}>
              {' '}
              {menu.label}
            </Typography>
            <Box>
              {selected === menu.value ? (
                <>
                  {sortOrder === 'A' && <ArrowUpward />}{' '}
                  {sortOrder === 'D' && <ArrowDownward />}
                </>
              ) : null}
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
SortMenu.propTypes = {
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  sortFunc: PropTypes.func.isRequired,
};

export default SortMenu;
