import { useEffect, useState } from 'react';

import { useLocation } from 'react-router';
import {
  HotelBookingStatusOptions,
} from 'utilities/selectOptions';
import { searchInitValues } from '../utilities/searchFormUtils';

const useGetSearchFormData = () => {
  const { search } = useLocation();
  const [modifiedData, setModifiedData] = useState([]);
  const [initValues, setInitValues] = useState(searchInitValues);
  const query = new URLSearchParams(search);
  const searchDate = {
    user_name: query.get('user_name') || '',
    hotel_name: query.get('hotel_name') || '',
    start_date: query.get('start_date') || '',
    end_date: query.get('end_date') || '',
    status: query.get('status') || '',
    sublime_booking_id: query.get('sublime_booking_id') || '',
  };

  useEffect(() => {
    const data = [
      {
        label: 'Booked By',
        class: 12,
        placeholder: 'Booked By',
        fieldName: 'user_name',
        type: 'text',
        value: searchDate?.user_name,
      },
      {
        label: 'Hotel Name',
        class: 12,
        placeholder: 'Hotel Name',
        fieldName: 'hotel_name',
        type: 'text',
        value: searchDate?.hotel_name,
      },
      {
        label: 'Checkin',
        class: 6,
        placeholder: 'Checkin',
        fieldName: 'start_date',
        type: 'date',
        value: searchDate?.start_date,
      },
      {
        label: 'Checkout',
        class: 6,
        placeholder: 'Checkout',
        fieldName: 'end_date',
        type: 'date',
        value: searchDate?.end_date,
      },
      {
        label: 'Booking Id',
        class: 12,
        placeholder: 'Booking Id',
        fieldName: 'sublime_booking_id',
        type: 'text'
      },
      {
        label: 'Status',
        class: 12,
        placeholder: 'Status',
        fieldName: 'status',
        type: 'list',
        options: HotelBookingStatusOptions,
        value: searchDate?.status,
      },
    ];
    setModifiedData(data);
    setInitValues(prevState => ({
      ...prevState,
      ...searchDate,
    }));
  }, [search]);
  return { modifiedData, initValues, searchInitValues };
};

export default useGetSearchFormData;
