import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  // Button,
  Menu,
  MenuItem,
  Box,
  Avatar,
  Typography,
} from '@mui/material';
import { useLogoutMutation } from 'services/private/auth';
import { useSnackbar } from 'notistack';
import { onLoggedOut } from 'store/slices/authSlice';
import { useSelector, useDispatch } from 'react-redux';
import { Logout, PersonOutline } from '@mui/icons-material';
import useGetThemeColor from 'customHooks/useGetThemeColor';

function TopbarProfile() {
  const { enqueueSnackbar } = useSnackbar();

  const [isOpen, setIsOpen] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [logout] = useLogoutMutation();

  const primaryColor = useGetThemeColor();

  const user = useSelector(state => state.auth.user);

  const handleMenuClick = useCallback(e => {
    setIsOpen(e.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(null);
  }, []);

  const logoutFun = async () => {
    try {
      await logout();
      dispatch(onLoggedOut());
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  };

  return (
    <Box>
      <Avatar
        src={user.profile && user.profile.image}
        alt="avatar"
        onClick={handleMenuClick}
      />

      <Menu
        id="basic-menu"
        key={isOpen}
        open={!!isOpen}
        anchorEl={isOpen}
        onClose={handleClose}
        sx={{
          top: 40,
          '& li:hover': {
            backgroundColor: primaryColor,
          },
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          className="d-flex gap-2"
          onClick={() => {
            handleClose();
            navigate(`user/${user?.id}/profile`);
          }}
        >
          <PersonOutline /> <Typography>My Profile</Typography>
        </MenuItem>
        <MenuItem
          className="d-flex gap-2"
          onClick={() => {
            handleClose();
            logoutFun();
            window.location.reload();
            navigate('/auth/sign-in');
          }}
        >
          <Logout />
          <Typography>Log Out</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default TopbarProfile;
