import * as yup from 'yup';

export const createReportInitVal = {
  request_type: '',
  description: '',
  report_images: [],
  subject: ''
};

export const reateReportValidationSchema = yup.object({
  subject: yup.string().required('Required'),
  request_type: yup.string().required('Required'),
  description: yup.string().required('Required'),
});

export const helpAndSupportTableHeadings = [
  {
    id: 'order_number',
    disablePadding: true,
    label: 'Ticket #',
  },
  {
    id: 'subject',
    disablePadding: true,
    label: 'Subject',
  },
  {
    id: 'description',
    disablePadding: true,
    label: 'Description',
  },
  {
    id: 'created_at',
    disablePadding: true,
    label: 'Date',
  },
  {
    id: 'request_type',
    disablePadding: true,
    label: 'Type',
  },

  {
    id: 'sender',
    disablePadding: true,
    label: 'Created By',
  },
  {
    id: 'sender',
    disablePadding: true,
    label: 'Assigned By',
  },
  {
    id: 'sender',
    disablePadding: true,
    label: 'Assigned To',
  },

  {
    id: 'status',
    disablePadding: true,
    label: 'Status',
  },
];
export const ticketStatusOptions = [
  { value: 'in_progress', label: 'InProgress', selectedValue: 'InProgress' },
  { value: 'closed', label: 'Close', selectedValue: 'Close' },
];

export const ticketTypeOptions = [
  {
    id: 'Name',
    disablePadding: true,
    label: 'Name ',
  },
  {
    id: 'Department',
    disablePadding: true,
    label: 'Department',
  },
  {
    id: 'Email',
    disablePadding: true,
    label: 'Email',
  },
  {
    id: 'Action',
    disablePadding: true,
    label: 'Action',
  },
];
export const reportSubjectOptions = [
  {
    label: 'Complaint',
    value: 'Complaint',
  },
  {
    label: 'Suggestion',
    value: 'Suggestion',
  },
  {
    label: 'Question',
    value: 'Question',
  },
  {
    label: 'Feature',
    value: 'Feature',
  },
  {
    label: 'Bug',
    value: 'Bug',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];
