import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Box } from '@mui/material';
import propTypes from 'prop-types';
import useGetThemeColor from 'customHooks/useGetThemeColor';

// Register necessary components
ChartJS.register(
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  Filler
);

function Revenue({ revenueData }) {
  const { secondary, primaryLight } = useGetThemeColor();
  const data = {
    labels: Array.from({ length: 12 }, (_, i) => `M${i + 1}`), // 12 months
    datasets: [
      {
        label: 'LY',
        data: revenueData?.LY || [], // Sample data
        backgroundColor: secondary, // Blue with opacity
        fill: true, // Enable area fill
        tension: 0.1, // Smooth curves
        pointRadius: 0, // Hide points
        order: 2,
      },
      {
        label: 'CY',
        data: revenueData?.CY || [],
        backgroundColor: primaryLight, // Gray with opacity
        fill: true,
        tension: 0.1,
        pointRadius: 0,
        order: 1, // CY should be drawn first (so LY overlays it)
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false, // Make it fit container width
    scales: {
      x: {
        display: false, // Hide x-axis labels
        grid: {
          display: false, // Removes horizontal grid lines
        }
      },
      y: {
        ticks: {
          callback: value => `${value / 1000}K`, // Format y-axis labels
        },
        beginAtZero: true,
        grid: {
          display: false, // Removes horizontal grid lines
        }
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true, // Use small squares
        },
      },
    },
  };
  return (
    <Box sx={{ width: '100%', height: '120px' }}>
      {' '}
      <Line data={data} options={options} />
    </Box>
  );
}

Revenue.propTypes = {
  revenueData: propTypes.object.isRequired,
};

export default Revenue;
