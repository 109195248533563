import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TableBody, TableCell, TableRow, Chip } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';

import { useUpdateUserMutation } from 'services/private/users';
import ConfirmationPopup from 'containers/common/components/ConfirmationPopup';
import NoFoundTable from 'containers/common/components/NoFoundTable';
import { toTitleCase } from 'utilities/helpers';
import { tableCelStyle } from 'styles/mui/common/table-styles';

function AdminUserTableBody({ adminData }) {
  const [selectedUser, setSelectedUser] = useState(null);
  const [isOpenConModal, setIsOpenConModal] = useState(false);
  if (adminData?.length === 0) {
    return <NoFoundTable message="No adminData Found" col={12} />;
  }
  const [updateUser] = useUpdateUserMutation();

  const toggleConModal = () => setIsOpenConModal(!isOpenConModal);

  const handleOnConfirmation = async () => {
    await updateUser({
      id: selectedUser.profile.id,
      body: {
        status: selectedUser.profile.status === 'active' ? 'inactive' : 'active',
      },
    });
    toggleConModal();
  };
  return (
    <TableBody sx={tableCelStyle}>
      {adminData?.map(
        data => data?.profile && (
        <TableRow
          key={data.id}
          role="button"
          tabIndex="-1"
        >
          <TableCell>
            {data.id || 'N/A'}
          </TableCell>
          <TableCell>
            {data.profile.first_name || 'N/A'}
          </TableCell>
          <TableCell>
            {data.profile.last_name || 'N/A'}
          </TableCell>
          <TableCell>
            {data.email || 'N/A'}
          </TableCell>
          <TableCell>
            {data.profile.phone_number || 'N/A'}
          </TableCell>
          <TableCell
            sx={{ textTransform: 'capitalize' }}

          >
            {data.user_type || 'N/A'}
          </TableCell>
          <TableCell
            sx={{ textTransform: 'capitalize' }}

          >
            {toTitleCase(data.profile.department_name) || 'N/A'}
          </TableCell>
          <TableCell>
            <Chip
              sx={{ textTransform: 'capitalize' }}
              label={data.profile.status}
              icon={<ArrowDropDown />}
              color={data.profile.status === 'active' ? 'success' : 'error'}
              onClick={() => {
                setSelectedUser(data);
                toggleConModal();
              }}
            />
          </TableCell>
        </TableRow>
        )
      )}
      <ConfirmationPopup
        isOpen={isOpenConModal}
        toggle={toggleConModal}
        onConfirmation={handleOnConfirmation}
        message={`Are you sure you want to ${
          selectedUser?.profile?.status === 'active' ? 'Inactive' : 'Active'
        } ${selectedUser?.profile?.first_name}?`}
      />
    </TableBody>
  );
}
AdminUserTableBody.propTypes = {
  adminData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AdminUserTableBody;
