import React, { useCallback } from 'react';
import MetaTagsHelmet from 'containers/common/components/MetaTagsHelmet';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { handleSortClickItem } from 'utilities/helpers';
import { Box, Toolbar, Typography } from '@mui/material';
import SearchPopup from 'containers/common/components/SearchPopup';
import SearchForm from 'containers/common/components/SearchForm';
import SortMenu from 'containers/common/components/SortMenu';
import BookingTable from './components/BookingTable';
import { sortOptions } from './utilities/data';
import useGetSearchFormData from './customHooks/useGetSearchFormData';

function Booking() {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { initValues, modifiedData, searchInitValues } = useGetSearchFormData();
  const handleSortItemClicked = useCallback(
    item => {
      handleSortClickItem(item, searchParams, navigate, pathname);
    },
    [searchParams, pathname]
  );
  return (
    <>
      <MetaTagsHelmet moduleName="Bookings" />
      <Toolbar disableGutters className="justify-content-between">
        <Typography className="my-4" variant="pageTitle">
          Hotel Bookings
        </Typography>
        <Box className="d-flex gap-1">
          <SearchPopup>
            {toggle => (
              <SearchForm
                toggle={toggle}
                initValues={initValues}
                modifiedData={modifiedData}
                resetValues={searchInitValues}
              />
            )}
          </SearchPopup>
          <SortMenu sortOptions={sortOptions} sortFunc={handleSortItemClicked} />
        </Box>
      </Toolbar>
      <BookingTable />
    </>
  );
}

export default Booking;
