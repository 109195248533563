import React from 'react';
import { Box, Paper, Table, TableContainer, Typography } from '@mui/material';
import TableLoader from 'containers/common/components/TableLoader';
import { useGetBookingsAnalyticsQuery } from 'services/private/dashboard';
import { tableContainerStyle, tableStyle } from 'styles/mui/common/table-styles';
import BookingsDataTableHead from './BookingsDataTableHead';
import BookingsDataTableBody from './BookingsDataTableBody';

function BookingsDataTable() {
  const { data, isFetching } = useGetBookingsAnalyticsQuery();
  return (
    <>
      <Box className="d-flex justify-content-between my-2">
        <Typography variant="body2">Current Date: {data?.data?.date}</Typography>
        <Typography variant="body1" fontSize={18} fontWeight="bold" className="mb-2">
          Luxury Booking Analytics
        </Typography>
        <Typography variant="body1" fontSize={18} fontWeight="bold" className="mb-2" />
      </Box>
      <TableContainer sx={tableContainerStyle} component={Paper}>
        <Table stickyHeader sx={tableStyle}>
          <BookingsDataTableHead rowCount={0} />
          {isFetching ? (
            <TableLoader />
          ) : (
            <BookingsDataTableBody analyticsData={data?.data?.analytics} />
          )}
        </Table>
      </TableContainer>
    </>
  );
}
export default BookingsDataTable;
