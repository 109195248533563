/* eslint-disable import/prefer-default-export */

export const searchInitValues = {
  user_name: '',
  hotel_name: '',
  status: '',
  sublime_booking_id: '',
  start_date: '',
  end_date: '',
};
