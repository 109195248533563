import React, { useCallback, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import propTypes from 'prop-types';
import { Box, Card, CardContent, Grid, IconButton, Typography } from '@mui/material';
import { setIconByFileType } from 'utilities/helpers';
import { saveAs } from 'file-saver';
import CommonModal from 'containers/common/components/CommonModal';

function GridLabelValuePair({ label, value, mb }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleSaveFile = () => {
    saveAs(selectedImage);
  };
  const toggleModal = useCallback(() => setModalOpen(!isModalOpen), [isModalOpen]);
  return (
    <Box>
      {label !== 'Attachments' && (
        <Box>
          <Grid container mb={mb}>
            <Grid item xs={12} md={4}>
              <Typography variant="h4" fontWeight={600}>
                {label}:
              </Typography>
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography variant="body1">{value}</Typography>
            </Grid>
          </Grid>
        </Box>
      )}

      {label === 'Attachments' && (
        <Grid container mb={mb}>
          <Grid item xs={12} md={4}>
            <Typography variant="h4" fontWeight={600}>
              {label}:
            </Typography>
          </Grid>

          <Grid item xs={12} md={8}>
            {/* FILES CONTAINER */}
            <Box className="d-flex align-items-center my-3 gap-4">
              {value?.map(fileObj => (
                <Card
                  sx={{ cursor: 'pointer', borderRadius: '10px' }}
                  onClick={() => {
                    setSelectedImage(fileObj.file);
                    toggleModal();
                  }}
                  key={fileObj?.id}
                >
                  <CardContent className="text-center py-3 px-2">
                    <img src={setIconByFileType(fileObj?.file)} alt="file-Icon" />
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Grid>
        </Grid>
      )}
      <CommonModal isOpen={isModalOpen} toggle={toggleModal} title="File">
        <Box className="d-flex justify-content-end mb-2">
          <IconButton onClick={handleSaveFile}>
            <DownloadIcon />
          </IconButton>
        </Box>
        <Box sx={{ width: '450px', height: '350px' }}>
          <img src={selectedImage} alt="preview" style={{ maxWidth: '100%', maxHeight: '100%', height: 'auto', objectFit: 'contain' }} />
        </Box>
      </CommonModal>
    </Box>
  );
}
GridLabelValuePair.propTypes = {
  label: propTypes.string.isRequired,
  value: propTypes.string.isRequired,
  mb: propTypes.number,
};

GridLabelValuePair.defaultProps = {
  mb: 2,
};
export default GridLabelValuePair;
