import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow } from '@mui/material';
import useGetThemeColor from 'customHooks/useGetThemeColor';
import { tableHeadStyles } from 'styles/mui/common/table-styles';
import { adminUsersTableHeadings } from '../utilities/data';

function AdminUserTableHead({ order, orderBy }) {
  const { tablePallete, whiteClr } = useGetThemeColor();
  return (
    <TableHead sx={tableHeadStyles}>
      <TableRow>
        {adminUsersTableHeadings.map((row, index) => (
          <TableCell
            key={index}
            className="fw-semibold"
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
            sx={{
              backgroundColor: tablePallete.headBg,
              color: whiteClr,
            }}
          >
            {row.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
AdminUserTableHead.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default AdminUserTableHead;
