import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Tooltip } from '@mui/material';
import { Search } from '@mui/icons-material';
import CustomDrawer from './CustomDrawer';

function SearchPopup({ children }) {
  const [isDrawerOpen, setDrawerState] = useState(false);

  const toggleDrawer = () => setDrawerState(prevState => !prevState);

  return (
    <>
      <Box className="mr-3">
        <Tooltip label="Search">
          <Button onClick={toggleDrawer} size="small" color="primary" variant="contained">
            <Search />
          </Button>
        </Tooltip>
      </Box>
      <CustomDrawer
        isOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        header={<h4>Personalized Search</h4>}
      >
        <div className="theme-light px-2" style={{ width: 380 }}>
          {children(toggleDrawer)}
        </div>
      </CustomDrawer>
    </>
  );
}

SearchPopup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

export default SearchPopup;
