import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Container, Divider, Typography, useMediaQuery } from '@mui/material';
import useAuthInfo from 'customHooks/useAuthInfo';
import { mainContainerStyles } from 'styles/mui/containers/layoutStyles';
import Topbar from './components/topbar/Topbar';
import Sidebar from './components/sidebar/Sidebar';

function LayoutWrapper() {
  const { isAuthenticated } = useAuthInfo();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');

  useEffect(() => {
    if (isMobile) setSidebarOpen(true);
    else {
      setSidebarOpen(false);
    }
  }, [isMobile]);

  return (
    isAuthenticated && (
      <Box>
        <Topbar />

        <Box className="d-flex align-items-start w-100" sx={{ mt: '60px' }}>
          <Sidebar isOpen={isSidebarOpen} />
          {/* <Box> */}
          <Container sx={mainContainerStyles(isSidebarOpen)} className="p-1">
            <Box>
              <Outlet />
            </Box>
            <Box>
              <Divider />
              <Typography variant="body2" textAlign="center" className="mt-2 ">
                Powered by Beyond Eris Solutions
              </Typography>
            </Box>
          </Container>
          {/* </Box> */}
        </Box>
      </Box>
    )
  );
}

export default LayoutWrapper;
