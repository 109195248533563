import React, { useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Box } from '@mui/material';
import propTypes from 'prop-types';
import useGetThemeColor from 'customHooks/useGetThemeColor';

// MUI COMPONENTS && ICONS
ChartJS.register(ArcElement, Tooltip, Legend);

function Hotels({ hotelsData }) {
  const { secondary, primaryLight, primary } = useGetThemeColor();
  const labelsData = useMemo(
    () => hotelsData?.map(item => item.name.split(' ').slice(0, 2).join(' ')),
    [hotelsData]
  );

  const dataset = useMemo(() => hotelsData?.map(item => item.count), [hotelsData]);
  const data = {
    labels: labelsData,
    datasets: [
      {
        data: dataset, // Example values
        backgroundColor: [primary, primaryLight, secondary], // Matching colors
        borderWidth: 1,
        cutout: '60%', // Creates the donut hole effect
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allow resizing
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'rect',
          boxWidth: 10, // Keeps legend small
          padding: 10,
        },
      },
    },
  };
  return (
    <Box sx={{ width: '100%', height: '270px' }}>
      <Doughnut data={data} options={options} />
    </Box>
  );
}

Hotels.propTypes = {
  hotelsData: propTypes.array.isRequired,
};

export default Hotels;
