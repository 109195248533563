import React from 'react';
import { Box, Toolbar, Tooltip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import HelpIcon from '@mui/icons-material/Help';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import Notifications from 'containers/pages/chat/notifications/Notifications';
import useAuthInfo from 'customHooks/useAuthInfo';
import { topbarStyling, topbarRightStyling } from 'styles/mui/containers/topbar';
import logo from 'assets/Logo-Black.png';
import TopbarProfile from './TopbarProfile';

function Topbar() {
  const navigate = useNavigate();
  const { isSuperUser } = useAuthInfo();

  return (
    <Toolbar
      sx={{
        ...topbarStyling,
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.1)',
        backgroundColor: '#f0f0f0',
        width: '100%',
      }}
      className="no-print d-flex justify-content-between align-items-center overflow-hidden"
    >
      <Box>
        <img src={logo} alt="logo" width={200} height={50} />
      </Box>
      <Box className="d-flex align-items-center gap-2">
        {isSuperUser && (
          <Tooltip title="Contact to BES Tec Team" onClick={() => navigate('/tec-team/help-and-support')}>
            <AddIcon className="pointer" />
          </Tooltip>
        )}
        <SearchIcon className="pointer" />
        <HelpIcon className="pointer" />
        <Notifications />
        <Box sx={topbarRightStyling}>
          <TopbarProfile />
        </Box>
      </Box>
    </Toolbar>
  );
}

export default Topbar;
