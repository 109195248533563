import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import { sidebarCollapse, sidebarExpand } from 'styles/containers/wrapper';
import SidebarContent from './SidebarContent';

function Sidebar({ isOpen }) {
  return (
    <Box style={!isOpen ? sidebarExpand : sidebarCollapse} className="no-print">
      <SidebarContent isOpen={isOpen} />
    </Box>
  );
}

export default Sidebar;
Sidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};
