import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, TableBody, TableCell, TableRow } from '@mui/material';
import { useTableContext } from 'context/TableContext';
import useHandleApiResponse from 'customHooks/useHandleApiResponse';
import { useAssignReportsMutation } from 'services/private/helpandsupport';
import { toTitleCase } from 'utilities/helpers';
import { tableCelStyle } from 'styles/mui/common/table-styles';

function AssignTicketTableBody({ userDetail }) {
  const { deleteToggle, setSelected, selected } = useTableContext();
  const [assignReports, { error, isSuccess, isLoading }] = useAssignReportsMutation();
  useHandleApiResponse(error, isSuccess, 'Ticket Assigned Successfully');
  const handelClick = async id => {
    const payload = {
      report_numbers: selected,
      assignee: id,
    };
    await assignReports(payload);
    deleteToggle();
    setSelected([]);
  };
  return (
    <TableBody sx={tableCelStyle}>
      {userDetail?.map(user => (
        <TableRow key={user.id} role="button" tabIndex="-1">
          <TableCell sx={tableCelStyle}>
            {`${user?.profile?.first_name} ${user?.profile?.last_name[0]}` || 'N/A'}
          </TableCell>
          <TableCell sx={{ color: 'primary.main', fontWeight: 'bold' }}>
            {toTitleCase(user?.profile?.department_name || 'N/A')}
          </TableCell>
          <TableCell>{user.email || 'N/A'}</TableCell>
          <TableCell>
            <Button
              variant="contained"
              startIcon={isLoading ? <CircularProgress size={20} /> : undefined}
              disabled={isLoading}
              onClick={() => handelClick(user?.profile?.id)}
            >
              Assign
            </Button>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
}
AssignTicketTableBody.propTypes = {
  userDetail: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default AssignTicketTableBody;
