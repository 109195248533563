import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js';
import { Box } from '@mui/material';
import propTypes from 'prop-types';
import useGetThemeColor from 'customHooks/useGetThemeColor';

// Register necessary components
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

function UserRegistration({ usersData }) {
  const { secondary, primaryLight } = useGetThemeColor();
  const data = {
    labels: ['LY', 'CY'],
    datasets: [
      {
        label: 'Sales',
        data: [usersData?.LY, usersData?.CY], // Data values
        backgroundColor: [secondary, primaryLight], // Blue & Gray colors
        borderRadius: 20, // Rounded corners
        barThickness: 40, // Adjust bar thickness
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allow full width scaling
    scales: {
      x: {
        ticks: {
          font: {
            size: 14, // Adjust font size
          },
        },
        grid: {
          display: false, // Removes horizontal grid lines
        },
      },
      y: {
        ticks: {
          callback: value => `${value / 1000}K`, // Format y-axis labels
        },
        beginAtZero: true,
        grid: {
          display: false, // Removes horizontal grid lines
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide legend
      },
    },
  };

  return (
    <Box sx={{ width: '100%', height: '120px' }}>
      <Bar data={data} options={options} />
    </Box>
  );
}

UserRegistration.propTypes = {
  usersData: propTypes.object.isRequired,
};

export default UserRegistration;
