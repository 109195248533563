import React from 'react';
import { Checkbox, TableBody, TableCell, TableRow, Chip } from '@mui/material';
import moment from 'moment';
import NoFoundTable from 'containers/common/components/NoFoundTable';
import { useNavigate } from 'react-router-dom';
import { useTableContext } from 'context/TableContext';
import { toTitleCase, truncateText } from 'utilities/helpers';
// import { useSelector } from 'react-redux';
import { tableCelStyle } from 'styles/mui/common/table-styles';

function HelpAndSupportTableBody() {
  const {
    data,
    isSelected: handleSelect,
    handleClick,
    shouldShowButton,
  } = useTableContext();
  // const { user: userInfo } = useSelector(state => state.auth);
  const navigate = useNavigate();

  const handleOpenTicketDetail = async reportNumber => {
    navigate(`/ticket/details/${reportNumber}`);
  };
  function getStatusColor(status) {
    if (status === 'closed') {
      return 'error';
    }
    if (status === 'pending') {
      return 'warning';
    }
    return 'success';
  }
  if (data.length === 0) {
    return <NoFoundTable col={12} message="No data Found!" />;
  }
  return (
    <TableBody sx={tableCelStyle}>
      {data?.map(reportData => {
        const isSelected = handleSelect(reportData?.report_number);

        return (
          <TableRow
            role="checkbox"
            aria-checked={isSelected}
            tabIndex={-1}
            key={reportData.report_number}
            selected={isSelected}
            className="pointer"
            onClick={() => {
              handleOpenTicketDetail(reportData.report_number);
            }}
          >
            {shouldShowButton() && (
              <TableCell>
                <Checkbox
                  variant="mutedColor"
                  checked={isSelected}
                  onClick={event => {
                    event.stopPropagation();
                    handleClick(event, { ...reportData, id: reportData.report_number });
                  }}
                />
              </TableCell>
            )}

            <TableCell>{reportData.report_number}</TableCell>
            <TableCell>{truncateText(reportData.subject, 30) || '-'}</TableCell>
            <TableCell>{truncateText(reportData.description, 25)}</TableCell>
            <TableCell>
              {reportData.created_at && moment(data.created_at).format('YYYY-MM-DD')}
            </TableCell>
            <TableCell>{reportData.request_type || '-'}</TableCell>
            <TableCell>
              {`${reportData.reporter_first_name} ${reportData.reporter_last_name}` ||
                '-'}
            </TableCell>
            <TableCell>
              {`${reportData.assigned_to_first_name || ''} ${
                reportData.assigned_to_last_name || ''
              }` || '-'}
            </TableCell>
            <TableCell>
              {`${reportData.receiver_first_name || ''} ${
                reportData.receiver_last_name || ''
              }` || '-'}
            </TableCell>
            <TableCell>
              <Chip
                sx={{ textTransform: 'capitalize' }}
                label={toTitleCase(reportData.status)}
                color={getStatusColor(reportData?.status)}
              />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}

export default HelpAndSupportTableBody;
