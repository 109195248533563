import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import useGetThemeColor from 'customHooks/useGetThemeColor';
import { tableHeadStyles } from 'styles/mui/common/table-styles';
import {
  bookingsDataTableHeadings,
  bookingsDataTableMainHeadings,
} from '../utilities/data';

function BookingsDataTableHead() {
  const { tablePallete, whiteClr } = useGetThemeColor();
  return (
    <TableHead sx={{ ...tableHeadStyles, ' & th': { padding: '10px', textAlign: 'center' } }}>
      <TableRow>
        {bookingsDataTableMainHeadings.map((row, index) => (
          <TableCell
            key={index}
            className="fw-semibold"
            colSpan={2}
            sx={{
              backgroundColor: tablePallete.headBg,
              color: whiteClr,
            }}
            padding={row.disablePadding ? 'none' : 'default'}
          >
            {row.label}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        {bookingsDataTableHeadings.map((row, index) => (
          <TableCell
            key={index}
            padding={row.disablePadding ? 'none' : 'default'}
            sx={{
              borderRight: row.border ? `1px solid ${tablePallete.borderClr}` : 'none',
              backgroundColor: tablePallete.subHeadBg,
              fontSize: '14px',
              lineHeight: 1.43,
            }}
          >
            {row.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default BookingsDataTableHead;
