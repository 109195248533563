import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Toolbar, Typography } from '@mui/material';
import { useGetAllReportsQuery } from 'services/private/helpandsupport';
import withTable from 'hoc/withTable';
import MetaTagsHelmet from 'containers/common/components/MetaTagsHelmet';
import useGetParams from 'customHooks/useGetParams';
import { TableContext } from 'context/TableContext';
import useAuthInfo from 'customHooks/useAuthInfo';
import HelpAndSupportTable from './components/HelpAndSupportTable';
import AssignTicketsModal from './components/AssignTicketsModal';

function HelpAndSupport({ tableProps }) {
  const { selected, deleteToggle, setSelected } = tableProps;
  const [apiParams] = useGetParams();
  const { isSuperUser, isHelpAndSupportHead, isTecTeamHead, isTecTeamStaff } =
    useAuthInfo();
  const { data: itemsData = {}, isLoading } = useGetAllReportsQuery(apiParams, {
    skip: !apiParams,
  });
  const { results: items = [], count = 0 } = itemsData;
  const shouldShowButton = () => {
    if (isSuperUser) return apiParams?.is_admin_report === 'false';
    if (isTecTeamHead) return apiParams?.is_admin_report === 'true';
    if (isTecTeamStaff) return false;
    if (isHelpAndSupportHead) return apiParams?.is_admin_report === 'false';
    return false;
  };
  const contextValues = useMemo(
    () => ({ ...tableProps, data: items, dataCount: count, isLoading, shouldShowButton }),
    [tableProps, items, isLoading, count, shouldShowButton]
  );

  return (
    <>
      <MetaTagsHelmet moduleName="Help & Support" />
      {selected.length === 0 && (
        <Typography className="my-4" variant="pageTitle">
          {apiParams?.is_admin_report === 'false'
            ? 'Customer Service'
            : 'Technical Support'}
        </Typography>
      )}

      {selected.length > 0 && shouldShowButton() && (
        <Toolbar className="justify-content-between p-4">
          <Box className="d-flex gap-2">
            <Button
              color="primary"
              variant="contained"
              size="small"
              className="icon-btn"
              onClick={deleteToggle}
            >
              Assign Ticket
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={() => setSelected([])}
            >
              Clear
            </Button>
          </Box>

          <Box className="pt-2">
            <Box className="green-bubble" /> {selected.length} item
            {selected.length > 1 && 's'} selected{' '}
          </Box>
        </Toolbar>
      )}

      <TableContext.Provider value={contextValues}>
        <AssignTicketsModal title={apiParams?.is_admin_report === 'false'} />
        <HelpAndSupportTable />
      </TableContext.Provider>
    </>
  );
}
HelpAndSupport.propTypes = {
  tableProps: PropTypes.object.isRequired,
};

export default withTable(HelpAndSupport);
