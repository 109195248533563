/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import useAuthInfo from 'customHooks/useAuthInfo';

import { Book, Person, SupportAgent } from '@mui/icons-material';
import {
  DashboardItem,
  HelpAndSupportItem,
  BookingSubTabs,
  UsersSubTabs,
  privateJetItem,
  limousineItem,
  restaurantItem,
  MarkupItem,
  hotelsItem,
  citiesItem,
  HelpAndSupportItemTecTeam,
} from '../../utilities/data';

const useGetSidebarLinks = () => {
  const {
    isSuperUser,
    isOperationsHead,
    isOperationsStaff,
    isHelpAndSupportHead,
    isHelpAndSupportStaff,
    isTecTeamHead,
    isTecTeamStaff,
    isQaTeam,
  } = useAuthInfo();

  const superUserSidebar = [
    DashboardItem,
    ...(isSuperUser
      ? [
          {
            data: UsersSubTabs,
            title: 'Users',
            path: null,
            icon: <Person />,
            permissions: [],
          },
        ]
      : []),
    // ...(isSuperUser || isHelpAndSupportHead || isHelpAndSupportStaff
    //   ? [
    //       {
    //         data: HelpAndSupportItem,
    //         title: 'Help & Support',
    //         path: null,
    //         icon: <SupportAgent />,
    //         permissions: [],
    //       },
    //     ]
    //   : []),
    ...(isSuperUser || isOperationsHead || isOperationsStaff
      ? [
          {
            data: BookingSubTabs,
            title: 'Hotels',
            path: null,
            icon: <Book />,
            permissions: [],
          },

          privateJetItem,
          limousineItem,
          restaurantItem,
        ]
      : []),
    ...(isSuperUser || isQaTeam ? [hotelsItem] : []),
    ...(isSuperUser ? [citiesItem] : []),
    ...(isSuperUser ? [MarkupItem] : []),
  ];

  const isSettingsSection = [
    ...(isSuperUser || isHelpAndSupportHead || isHelpAndSupportStaff
      ? [
          {
            data: HelpAndSupportItem,
            title: 'Help & Support',
            path: null,
            icon: <SupportAgent />,
            permissions: [],
          },
        ]
      : []),
    ...(isTecTeamStaff || isTecTeamHead
      ? [
          {
            data: HelpAndSupportItemTecTeam,
            title: 'Help & Support',
            path: null,
            icon: <SupportAgent />,
            permissions: [],
          },
        ]
      : []),
  ];

  return {
    superUserSidebar,
    isSettingsSection,
  };
};

export default useGetSidebarLinks;
