/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import { Form, Formik } from 'formik';
import { Grid, Paper, Stack, Typography } from '@mui/material';
import { FormikField, FormikSelect } from 'shared/form';
import SubmitBtn from 'shared/form/SubmitBtn';
import { useAssignTicketToTecTeamMutation } from 'services/private/helpandsupport';
import { useNavigate } from 'react-router-dom';
import { transformToFormData } from 'utilities/helpers';
import useHandleApiResponse from 'customHooks/useHandleApiResponse';
import { createReportInitVal, reateReportValidationSchema, reportSubjectOptions } from '../utilities/data';

function HelpAndSupportContactForm() {
  const navigate = useNavigate();
  const [assignTicket, { error, isSuccess }] = useAssignTicketToTecTeamMutation();
  useHandleApiResponse(error, isSuccess, 'Report  Created Successfully!');
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" padding={4}>
        <Typography variant="h3">Create Ticket</Typography>
      </Stack><Paper>
        <Formik
          initialValues={createReportInitVal}
          validationSchema={reateReportValidationSchema}
          onSubmit={async (values, { resetForm }) => {
            const payload = {
              ...values,
              is_admin: true,
            };
            const formdata = transformToFormData(payload);
            await assignTicket(formdata);
            navigate('/help-and-support?is_admin_report=true');

            resetForm();
          }}
        >
          {() => (
            <Form className="mb-4 p-4">
              <Grid container columnSpacing={3} rowGap={3} justifyContent="center">
                <Grid item xs={12} md={6}>
                  <FormikSelect
                    label="Request Type:"
                    placeholder="Request Type"
                    name="request_type"
                    variant="outlined"
                    isRequired
                    options={reportSubjectOptions}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikField
                    label="Subject:"
                    placeholder="Subject"
                    name="subject"
                    variant="outlined"
                    isRequired
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormikField
                    label="Description:"
                    textArea
                    placeholder="Write your message here..."
                    name="description"
                    variant="outlined"
                    isRequired
                  />
                </Grid>
                {/* <Grid item xs={12}>
            <UploadFIleButton
              formValues={values}
              setFieldValue={setFieldValue}
              fieldName="report_images"
            />
            <FormUploadFiles name="report_images" />
          </Grid> */}
              </Grid>
              <SubmitBtn label="Create" size="large" />
            </Form>
          )}

        </Formik>
      </Paper>
    </>
  );
}

export default HelpAndSupportContactForm;
