/* eslint-disable react/require-default-props */
import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import CommonModal from 'containers/common/components/CommonModal';
import { useTableContext } from 'context/TableContext';
import AssignTicketTable from './AssignTicketTable';

function AssignTicketsModal({ title }) {
  const { deleteToggle, deleteModal } = useTableContext();
  return (
    <CommonModal
      isOpen={deleteModal}
      toggle={deleteToggle}
      title={title ? 'Support Users List' : 'Tec Support Users List'}
      isPopup
    >
      <Box sx={{ maxWidth: '800px', minWidth: '800px' }}>
        <AssignTicketTable title={title} />
      </Box>

    </CommonModal>
  );
}
AssignTicketsModal.propTypes = {
  title: PropTypes.string,
};

export default AssignTicketsModal;
