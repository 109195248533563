export const containerBoxStyles = {
  padding: '10px',
  borderRadius: '15px',
  boxShadow:
    'inset 2px 3px 4px 0 rgba(0, 0, 0, 0.1), inset -2px -3px 4px 0 rgba(0, 0, 0, 0.1)',
};

export const summaryCardStyles = {
  ...containerBoxStyles,
  boxShadow:
    '0 4px 10px 0 rgba(0, 0, 0, 0.1), inset 2px 3px 4px 0 rgba(0, 0, 0, 0.1), inset -2px -3px 4px 0 rgba(0, 0, 0, 0.1)',
  backgroundImage: 'linear-gradient(to bottom, #fdfdfd, #bbb)',
};
