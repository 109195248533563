import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Typography } from '@mui/material';
import StyledLinkCard from 'containers/common/components/StyledLinkCard';

function AnalyticsCard({ label = '', path = '', Icon, data, color, isFetching }) {
  const roundedNumber = Math.round(data);
  return (
    <StyledLinkCard
      to={path}
      sx={{
        backgroundColor: color,
        transition: 'transform 0.3s ease, color 0.3s ease',
        '&:hover': {
          color: '#525252',
          transform: 'scale(1.05)',
        },
      }}
    >

      <Box
        className="mx-auto"
      >{Icon && <Icon fontSize="large" />}
      </Box>
      <Typography mt={1} textAlign="center" variant="h4">
        {label}
      </Typography>
      {isFetching ? (
        <Box className=" flex justify-center items-center mt-1">
          <CircularProgress sx={{ color: 'black' }} size={20} />
        </Box>
      ) : (
        <Typography mt={1} textAlign="center" variant="h4">
          {roundedNumber}
        </Typography>
      )}

    </StyledLinkCard>
  );
}

AnalyticsCard.defaultProps = {
  Icon: null,
  data: 0,
  color: '',
  isFetching: false
};

AnalyticsCard.propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  Icon: PropTypes.elementType,
  data: PropTypes.number,
  color: PropTypes.string,
  isFetching: PropTypes.bool,
};

export default AnalyticsCard;
