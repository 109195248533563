import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Divider, Chip, Avatar, Grid } from '@mui/material';
// API HOOKS
import { useLazyListChatMessagesQuery } from 'services/private/chat';
// STYLES
import { chatTitleStyles, MessagesWrapperStyles } from 'styles/mui/containers/chat-box-styles';
// COMMON COMPONENTS
import SectionLoader from 'containers/common/loaders/SectionLoader';
// UTILITIES
import { formatName } from 'utilities/helpers';
import { webSocketUrl } from 'utilities/sockets-urls';
// Hooks
import useGetChatContext from 'customHooks/useGetChatContext';
import useConnectWebSocket from 'customHooks/useConnectWebSocket';
// COMPONENTS
import ChatMessageForm from './ChatMessageForm';
import MessageItem from './MessageItem';

function MessageList() {
  const { pendingMessages, setPendingMessages, selectedUser, handleGetLatestRoom, chatRoomId } = useGetChatContext();
  const socket = useConnectWebSocket(webSocketUrl(chatRoomId), chatRoomId);
  // API HOOK
  const [listChatMessages, { isLoading: chatMessagesLoading }] =
    useLazyListChatMessagesQuery();
  // STATE HOOKS
  const [isScrolling, setScrolling] = useState(false);
  const [chatMessages, _setChatMessages] = useState([]);
  const [queryParams, _setQueryParams] = useState({});
  // Ref
  const newMsgRef = useRef(null);
  const chatMessagesStateRef = useRef(chatMessages);
  const msgContainerRef = useRef(null);
  const queryPramsStateRef = useRef(queryParams);
  const totalMessageCountRef = useRef(null);

  // HANDLER FUNCTIONS

  const setQueryParams = url => {
    const query = new URLSearchParams(url?.split('chat/')[2]);

    const data = {
      limit: +query.get('limit'),
      offset: +query.get('offset'),
      id: chatRoomId,
    };

    queryPramsStateRef.current = data;
    _setQueryParams(data);
  };

  // CUSTOM STATE SETTER
  const setChatMessages = data => {
    chatMessagesStateRef.current = data;
    _setChatMessages(data);
  };

  const getPrevMessages = async () => {
    const msgsResp = await listChatMessages(queryPramsStateRef.current);

    totalMessageCountRef.current = msgsResp?.data?.count;
    const recentViewedMsgId = chatMessagesStateRef.current?.at(-1);
    const getRecentViewedMsg = document.getElementById(`message-${recentViewedMsgId.id}`);
    getRecentViewedMsg?.scrollIntoView();

    const data = [...chatMessagesStateRef.current, ...msgsResp.data.results];

    const filteredData = data.filter(
      (item, idx, array) => array.findIndex(value => value.id === item.id) === idx
    );

    setChatMessages(filteredData);
    setQueryParams(msgsResp.data?.next);
  };
  // WEB-SOCKET
  useEffect(() => {
    if (socket && selectedUser) {
      socket.onmessage = e => {
        const data = JSON.parse(e.data);

        setPendingMessages(prevState => {
          if (prevState?.length > 0) prevState?.shift();

          return prevState;
        });

        setChatMessages([data, ...chatMessagesStateRef.current]);

        handleGetLatestRoom();
      };
    }
  }, [socket, selectedUser]);

  useEffect(() => {
    const chatParams = { id: chatRoomId, limit: 15, offset: 0 };
    _setQueryParams(chatParams);
    if (chatParams.id) {
      listChatMessages(chatParams).then(res => {
        setChatMessages(res?.data);
        totalMessageCountRef.current = res?.data?.count;
        setQueryParams(res.data?.next);
      });
    }
  }, [chatRoomId]);

  useEffect(() => {
    if (!isScrolling) newMsgRef.current?.scrollIntoView({ behaviour: 'smooth' });
  }, [chatMessages, isScrolling, pendingMessages]);

  useEffect(() => {
    msgContainerRef.current?.addEventListener('scroll', () => {
      const container = msgContainerRef.current;
      if (
        container?.scrollTop === 0 &&
        chatMessagesStateRef.current?.length < totalMessageCountRef.current
      ) {
        setScrolling(true);
        getPrevMessages();
      }
      if (
        container &&
        container.scrollHeight - container.scrollTop === container.clientHeight
      ) {
        setScrolling(false);
      }
    });

    return () => {
      msgContainerRef.current?.removeEventListener('scroll', () => {});
    };
  }, [msgContainerRef.current]);

  // CONSTANTS
  const isChatSelected = Boolean(chatRoomId);
  // HANDLER FUNCTIONS
  const handleGetChatMessages = () => {
    const chatParams = { id: chatRoomId, limit: 15, offset: 0 };
    listChatMessages(chatParams).then(res => {
      setChatMessages(res?.data);
      totalMessageCountRef.current = res?.data?.count;
      setQueryParams(res.data?.next);
    });
  };
  return (
    <Box>
      <Box sx={chatTitleStyles}>
        {isChatSelected && (
          <Grid container className="justify-content-between align-items-center p-2">
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Box className="d-flex align-items-center gap-2">
                <Avatar src={selectedUser?.selectedUserProfile} alt="userProfile" />
                <Typography variant="h6" color="primary" sx={{ lineHeight: 1 }}>
                  {formatName(
                    selectedUser?.selectedUserFirstName,
                    selectedUser?.selectedUserLastName,
                    selectedUser?.selectedUserName,
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Box className="d-flex justify-content-end gap-2">
                <Chip
                  sx={{
                    textTransform: 'capitalize',
                    color: 'white',
                    fontWeight: '600',
                    fontSize: '14px',
                    backgroundColor: '#D4B35B',
                    ':hover': { bgcolor: '#d9c182', },
                  }}
                  label={(
                    <span>
                      <Typography
                        component="span"
                        sx={{ fontSize: '15px', color: 'white', fontWeight: '600' }}
                      >
                        description:
                      </Typography>{' '}
                      {selectedUser?.selectedUserDispute?.description?.length > 30
                        ? `${selectedUser.selectedUserDispute.description.slice(0, 30)}...`
                        : selectedUser.selectedUserDispute.description}
                    </span>
              )}
                />
                {selectedUser?.selectedUserDispute?.status === 'closed' && (
                <Chip
                  sx={{
                    textTransform: 'capitalize',
                    color: 'white',
                    fontWeight: '600',
                    fontSize: '14px',
                    backgroundColor: 'error.main',
                  }}
                  label="Ticket is Closed"
                />
                )}

              </Box>
            </Grid>
          </Grid>

        )}
      </Box>

      {isChatSelected ? (
        <Box>
          <Box sx={MessagesWrapperStyles} ref={msgContainerRef} id="cont">
            {chatMessagesLoading ? (
              <Box className="d-flex justify-content-center align-items-center h-100">
                <SectionLoader />
              </Box>
            ) : (
              <Box
                sx={{ height: 'auto' }}
                className="d-flex flex-column-reverse justify-content-end p-3"
              >
                <Box className="d-flex flex-column justify-content-end">
                  {pendingMessages?.map(item => (
                    <MessageItem
                      key={item?.id}
                      msgItem={item}
                      handleGetMessages={handleGetChatMessages}
                      isPending
                    />
                  ))}
                </Box>

                <Box className="d-flex flex-column-reverse justify-content-end">
                  {chatMessages?.map(item => (
                    <MessageItem
                      key={item?.id}
                      msgItem={item}
                      handleGetMessages={handleGetChatMessages}
                    />
                  ))}
                </Box>
              </Box>
            )}

            <Box ref={newMsgRef} />
          </Box>

          <Divider light />

          <ChatMessageForm socket={socket} />
        </Box>
      ) : (
        <Box
          className="d-flex justify-content-center align-items-center m-0"
          sx={{ height: '60vh' }}
        >
          <Typography variant="h6" color="primary" sx={{ lineHeight: 1 }}>
            No Chat Selected Yet
          </Typography>
        </Box>
      )}
    </Box>
  );
}
export default MessageList;
