import { Typography, Grid, Box } from '@mui/material';
import React from 'react';
import { containerBoxStyles } from 'styles/mui/containers/dashboard';
import { useGetPerformanceMetricsQuery } from 'services/private/dashboard';
import Booking from './components/Booking';
import Revenue from './components/Revenue';
import UserRegistration from './components/UserRegistration';
import Growth from './components/Growth';

function Performance() {
  const { data } = useGetPerformanceMetricsQuery();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={6}>
        <Box sx={containerBoxStyles}>
          <Typography variant="body2" mb={2}>
            Bookings
          </Typography>
          <Booking bookingData={data?.performance?.booking} />
        </Box>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box sx={containerBoxStyles}>
          <Typography variant="body2" mb={2}>
            Revenue
          </Typography>
          <Revenue revenueData={data?.performance?.revenue} />
        </Box>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box sx={containerBoxStyles}>
          <Typography variant="body2" mb={2}>
            User Registration
          </Typography>
          <UserRegistration usersData={data?.performance?.user_registration} />
        </Box>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box sx={containerBoxStyles}>
          <Typography variant="body2" mb={2}>
            Growth
          </Typography>
          <Growth growthData={data?.performance?.growth} />
        </Box>
      </Grid>
    </Grid>
  );
}

export default Performance;
