export const taxRateOptions = [
  { value: '5', label: 'Standard Rate [5%]' },
  { value: '0', label: 'Zero Rate [0%]' },
  { value: '1', label: 'Tax-Exempt [0%]' },
  { value: '2', label: 'Out of Scope [0%]' },
];

export const citiesOptions = [
  { label: 'Dubai', value: 'dubai' },
  { label: 'London', value: 'london' },
  { label: 'Abu Dhabi', value: 'abu dhabi' },
  { label: 'New York', value: 'new york' },
  { label: 'Paris', value: 'paris' },
  { label: 'Barcelona', value: 'barcelona' },
  { label: 'Geneva', value: 'geneva' },
  { label: 'Istanbul', value: 'istanbul' },
  { label: 'Jeddah', value: 'jeddah' },
  { label: 'Los Angeles,   California', value: 'Los Angeles,   California' },
  { label: 'Madrid', value: 'madrid' },
  { label: 'Milan', value: 'milan' },
  { label: 'Munich', value: 'munich' },
  { label: 'Riyadh', value: 'riyadh' },
  { label: 'Rome', value: 'rome' },
  { label: 'Venice Beach,   California', value: 'Venice Beach,   California' },
  { label: 'Vienna', value: 'Vienna' },
  { label: 'Zurich', value: 'Zurich' },
];

export const jetsCategoryOptions = [
  {
    label: 'Piston',
    value: 'PISTON',
  },
  {
    label: 'Turbo Prop',
    value: 'TURBOPROP',
  },
  {
    label: 'Entry level jet (VLJ)',
    value: 'ENTRY_LEVEL_JET',
  },
  {
    label: 'Light Jet',
    value: 'LIGHT_JET',
  },
  {
    label: 'Super Light Jet',
    value: 'SUPER_LIGHT_JET',
  },
  {
    label: 'Midsize Jet',
    value: 'MID_SIZE_JET',
  },
  {
    label: 'Super Midsize Jet',
    value: 'SUPER_MID_SIZE_JET',
  },
  {
    label: 'Heavy Jet',
    value: 'HEAVY_JET',
  },
  {
    label: 'Ultra Long Jet',
    value: 'ULTRA_LONG_JET',
  },
  {
    label: 'VIP Airliner',
    value: 'VIP_AIRLINER',
  },
  {
    label: 'Airliner (>19 PAX)',
    value: 'AIRLINER_OVER_19_PAX',
  },
];

export const vehicleTypeOptions = [
  {
    label: 'Business Class',
    value: 'BUSINESS_CLASS',
  },
  {
    label: 'First Class',
    value: 'FIRST_CLASS',
  },
  {
    label: 'Business Van/Suv',
    value: 'BUSINESS_CLASS_VAN_SUV',
  },
];

export const tripTypeOptions = [
  {
    label: 'One Way',
    value: 'ONE_WAY',
  },
  {
    label: 'Round Trip',
    value: 'ROUND_TRIP',
  },
  {
    label: 'Multi Leg',
    value: 'MULTI_LEG',
  },
];

// BOOKING OPTIONS
export const bookingStatusOptions = [
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Confirmed',
    value: 'CONFIRMED',
  },
  {
    label: 'Declined',
    value: 'DECLINED',
  },
  {
    label: 'Cancelled',
    value: 'CANCELLED',
  },
];

export const HotelBookingStatusOptions = [
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Confirmed',
    value: 'confirmed',
  },
  {
    label: 'Cancelled',
    value: 'cancelled',
  },
];

export const departmentOption = [
  { value: 'finance', label: 'Finance' },
  { value: 'hr', label: 'HR' },
  { value: 'sales', label: 'Sales' },
  { value: 'op', label: 'Operations' },
  { value: 'op-mice', label: 'Operations - MICE' },
  { value: 'op-corp', label: 'Operations - Corporate' },
  { value: 'op-vip', label: 'Operations - VIP' },
  { value: 'op-ticketing', label: 'Ticketing' },
];

export const statementTypeOptions = [
  { value: 'all', label: 'All' },
  // { value: 'outstanding', label: 'Outstanding' },
];

export const departmentOptions = [
  { value: 'finance', label: 'Finance' },
  { value: 'hr', label: 'HR' },
  { value: 'op', label: 'Operations' },
  // removed as no longer required in PASS product.
  // { value: 'sales', label: 'Sales' },
  { value: 'op-mice', label: 'Operations - MICE' },
  { value: 'op-corp', label: 'Operations - Corporate' },
  { value: 'op-vip', label: 'Operations - VIP' },
  { value: 'op-dmc', label: 'Operations - DMC' },
  { value: 'op-memb', label: 'Operations - Members' },
  { value: 'op-fit', label: 'Operations - FIT' },
];
export const superUserDepartmentOptions = [
  { value: 'hr', label: 'HR' },
];
export const openingBalanceTypes = [
  { value: 'true', label: 'Credit' },
  { value: 'false', label: 'Debit' },
];

export const martialStatusOptions = [
  {
    label: 'Single',
    value: 'Single'
  },
  {
    label: 'Married',
    value: 'Married'
  }
];

export const contractTypeOptions = [
  {
    label: 'Limited',
    value: 'Limited'
  },
  {
    label: 'Unlimited',
    value: 'Unlimited'
  }
];

export const dependentOptions = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
];

export const statusOptions = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Resigned',
    value: 'resigned',
  },
  {
    label: 'Terminated',
    value: 'terminated',
  }
];
