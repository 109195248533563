import React from 'react';
import { Box } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js';
import propTypes from 'prop-types';
import useGetThemeColor from 'customHooks/useGetThemeColor';

// Register required components
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

function Booking({ bookingData }) {
  const { secondary, primaryLight } = useGetThemeColor();
  const data = {
    labels: ['LY', 'CY'],
    datasets: [
      {
        label: 'Last Year',
        data: [bookingData?.LY, bookingData?.CY], // Data values
        backgroundColor: [secondary, primaryLight], // Blue & Gray colors
        barThickness: 30, // Adjust bar thickness
      },
    ],
  };
  const options = {
    indexAxis: 'y', // Make it horizontal
    responsive: true,
    maintainAspectRatio: false, // Allow full width scaling
    scales: {
      x: {
        ticks: {
          callback: value => `${value / 1000}k`, // Format x-axis labels
        },
        beginAtZero: true,
        grid: {
          display: false, // Removes vertical grid lines
        }
      },
      y: {
        grid: {
          display: false, // Removes horizontal grid lines
        }
      }
    },
    plugins: {
      legend: {
        display: false, // Hide legend
      },
    },
  };
  return (
    <Box sx={{ width: '100%', height: '120px' }}>
      <Bar data={data} options={options} />
    </Box>
  );
}

Booking.propTypes = {
  bookingData: propTypes.object.isRequired
};

export default Booking;
