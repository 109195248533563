import React from 'react';
import { Checkbox, TableBody, TableCell, TableRow, Chip } from '@mui/material';
import moment from 'moment';
import NoFoundTable from 'containers/common/components/NoFoundTable';
import { useNavigate } from 'react-router-dom';
import { useTableContext } from 'context/TableContext';
import { toTitleCase, truncateText } from 'utilities/helpers';
import { useSelector } from 'react-redux';

function HelpAndSupportTableBody() {
  const { data, isSelected: handleSelect, handleClick } = useTableContext();
  const { user: userInfo } = useSelector(state => state.auth);
  const navigate = useNavigate();

  const handleOpenTicketDetail = async reportNumber => {
    navigate(`/ticket/details/${reportNumber}`);
  };
  function getStatusColor(status) {
    if (status === 'closed') {
      return 'error';
    }
    if (status === 'pending') {
      return 'warning';
    }
    return 'success';
  }
  if (data.length === 0) {
    return <NoFoundTable col={12} message="No data Found!" />;
  }
  return (
    <TableBody
      sx={{
        '& td': {
          padding: '5px 10px',
        },
      }}
    >
      {data?.map(reportData => {
        const isSelected = handleSelect(reportData?.report_number);

        return (
          <TableRow
            role="checkbox"
            aria-checked={isSelected}
            tabIndex={-1}
            key={reportData.report_number}
            selected={isSelected}
            className="pointer"
            onClick={() => {
              handleOpenTicketDetail(reportData.report_number);
            }}
          >
            {userInfo?.user_type === 'admin' && (
              <TableCell padding="checkbox">
                <Checkbox
                  variant="mutedColor"
                  checked={isSelected}
                  onClick={event => {
                    event.stopPropagation();
                    handleClick(event, { ...reportData, id: reportData.report_number });
                  }}
                />
              </TableCell>
            )}

            <TableCell
              component="td"
              scope="row"
              padding="none"
              sx={{ textAlign: 'center' }}
            >
              {reportData.report_number}
            </TableCell>
            <TableCell
              component="td"
              scope="row"
              padding="none"
              sx={{ textAlign: 'center' }}
            >
              {reportData.subject || '-'}
            </TableCell>
            <TableCell
              component="td"
              scope="row"
              padding="none"
              sx={{ textAlign: 'center' }}
            >
              {truncateText(reportData.description, 25)}
            </TableCell>
            <TableCell component="td" scope="row" sx={{ textAlign: 'center' }}>
              {reportData.created_at && moment(data.created_at).format('YYYY-MM-DD')}
            </TableCell>
            <TableCell
              component="td"
              scope="row"
              padding="none"
              sx={{ textAlign: 'center' }}
            >
              {reportData.request_type || '-'}
            </TableCell>
            <TableCell
              component="td"
              scope="row"
              padding="none"
              sx={{ textAlign: 'center' }}
            >
              {`${reportData.reporter_first_name} ${reportData.reporter_last_name}` ||
                '-'}
            </TableCell>
            <TableCell
              component="td"
              scope="row"
              padding="none"
              sx={{ textAlign: 'center' }}
            >
              {`${reportData.assigned_to_first_name || ''} ${
                reportData.assigned_to_last_name || ''
              }` || '-'}
            </TableCell>
            <TableCell
              component="td"
              scope="row"
              padding="none"
              sx={{ textAlign: 'center' }}
            >
              {`${reportData.receiver_first_name || ''} ${
                reportData.receiver_last_name || ''
              }` || '-'}
            </TableCell>
            <TableCell
              component="td"
              scope="row"
              padding="none"
              sx={{ textAlign: 'center' }}
            >
              <Chip
                sx={{ textTransform: 'capitalize' }}
                label={toTitleCase(reportData.status)}
                color={getStatusColor(reportData?.status)}
              />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}

export default HelpAndSupportTableBody;
