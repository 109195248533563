/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Box, IconButton, List, ListItemButton, ListItemIcon, ListItemText, } from '@mui/material';
import { ArrowBack, Layers, Menu, Settings } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { sidebarItemStyles } from 'styles/mui/containers/layoutStyles';
import image from 'assets/Logo-Black.png';
import useGetThemeColor from 'customHooks/useGetThemeColor';
import useGetSidebarLinks from './customHooks/useGetSidebarLinks';
import SidebarItem from './SidebarItem';
import SidebarNested from './SidebarNested';

function SidebarContent({ isOpen, handleToggleSidebar }) {
  const [isSubSidebarOpen, setIsSubSidebarOpen] = useState(false);
  const [section, setSection] = useState('');
  const { superUserSidebar, settingMenus, subSidebarLinks, isMiscellaneousSection, isSettingsSection } = useGetSidebarLinks();

  const primaryColor = useGetThemeColor();

  const toggleSubSidebar = val => {
    setSection(val);
    setIsSubSidebarOpen(prev => !prev);
  };

  return (
    <List
      className={`sidebar ${isOpen ? 'px-3' : 'px-4'}`}
      style={{ width: isOpen ? '90px' : '240px', boxShadow: '0 2px 15px 0 rgba(0,0,0,0.2)', }}
    >
      <Box className="d-flex gap-1 justify-content-between align-items-center">
        <Box sx={{ minWidth: '40px' }}>
          <IconButton
            sx={{
              '&:hover': { backgroundColor: primaryColor, important: true },
            }}
            onClick={handleToggleSidebar}
          >
            <Menu />
          </IconButton>
        </Box>
        {!isOpen && <img src={image} alt={image} width={140} height={50} />}
      </Box>

      <List>
        {isSubSidebarOpen ? (
          <>
            <ListItemButton
              sx={{
                '&:hover': { backgroundColor: primaryColor },
              }}
              className="mb-2"
              onClick={() => toggleSubSidebar('')}
            >
              <ListItemIcon>
                <ArrowBack />
              </ListItemIcon>
              {!isOpen && <ListItemText primary="Back" />}
            </ListItemButton>
            {section === 'isMiscellaneous' ? (
              <> {subSidebarLinks.map(link => (
                <SidebarItem
                  type={link?.type}
                  icon={link?.icon}
                  title={link?.title}
                  path={link?.path}
                  key={link?.path}
                  isOpen={isOpen}
                />
              ))}
              </>
            ) : (
              <>  {settingMenus[0]?.data?.map(link => (
                <SidebarItem
                  type={link?.type}
                  icon={link?.icon}
                  title={link?.title}
                  path={link?.path}
                  key={link?.path}
                  isOpen={isOpen}
                />
              ))}
              </>
            )}

          </>
        ) : (
          superUserSidebar.map(link => {
            if (link?.data) {
              return (
                <SidebarNested
                  title={link?.title}
                  icon={link?.icon}
                  data={link?.data}
                  isOpen={isOpen}
                />
              );
            }
            return (

              <SidebarItem
                type={link?.type}
                icon={link?.icon}
                title={link?.title}
                path={link?.path}
                key={link?.path}
                isOpen={isOpen}
              />

            );
          })
        )}

        {(!isSubSidebarOpen) && (
          <>
            {isMiscellaneousSection && (
              <ListItemButton
                onClick={() => toggleSubSidebar('isMiscellaneous')}
                sx={{
                  ...sidebarItemStyles,
                  '&.Mui-selected, &:hover': { backgroundColor: primaryColor },
                }}
                className={`${isOpen ? '' : 'px-4'} menu-item`}
              >
                <ListItemIcon>
                  <Layers />
                </ListItemIcon>
                {!isOpen && <ListItemText primary="Miscellaneous" />}
              </ListItemButton>
            )}
            {isSettingsSection && (
              <ListItemButton
                onClick={() => toggleSubSidebar('settings')}
                sx={{
                  ...sidebarItemStyles,
                  '&.Mui-selected, &:hover': { backgroundColor: primaryColor },
                }}
                className={`${isOpen ? '' : 'px-4'} menu-item`}
              >
                <ListItemIcon>
                  <Settings />
                </ListItemIcon>
                {!isOpen && <ListItemText primary="Settings" />}
              </ListItemButton>
            )}
          </>
        )}
      </List>
    </List>
  );
}
export default SidebarContent;
SidebarContent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleToggleSidebar: PropTypes.func.isRequired,
};
