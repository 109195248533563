import React from 'react';
import { Typography, Box, Grid } from '@mui/material';

import MetaTagsHelmet from 'containers/common/components/MetaTagsHelmet';
import BookingsDataTable from './components/BookingsDataTable';
import Performance from './components/Performance';
import TopBookings from './components/TopBookings';
import TopCancellation from './components/TopCancellation';
import BookingSummary from './components/BookingSummary';
import BookingCalender from './components/BookingCalender';

function Dashboard() {
  return (
    <Box className="pb-5">
      <MetaTagsHelmet moduleName="Dashboard" />
      <BookingsDataTable />

      {/* <Box className="d-flex flex-column gap-4 my-4">
        <Box>
          <Typography
            variant="body1"
            textAlign="center"
            fontSize={18}
            fontWeight="bold"
            className="mb-2"
          >
            Performance (2025)
          </Typography>
          <Performance />
        </Box>
        <Box>
          <Typography
            variant="body1"
            textAlign="center"
            fontSize={18}
            fontWeight="bold"
            className="mb-2"
          >
            Top 3 Bookings (2025)
          </Typography>
          <TopBookings />
        </Box>
        <Box>
          <Typography
            variant="body1"
            textAlign="center"
            fontSize={18}
            fontWeight="bold"
            className="mb-2"
          >
            Top 3 Cancellations (2025)
          </Typography>
          <TopCancellation />
        </Box>
        <Box>
          <Typography
            variant="body1"
            textAlign="center"
            fontSize={18}
            fontWeight="bold"
            className="mb-2"
          >
            Booking Summary (2025)
          </Typography>
          <BookingSummary />
        </Box>
      </Box> */}

      <Grid container rowSpacing={5} columnSpacing={3} className="mt-3 mb-5">
        <Grid item xs={12} lg={6}>
          <Box>
            <Typography
              variant="body1"
              textAlign="center"
              fontSize={18}
              fontWeight="bold"
              className="mb-3"
            >
              Performance (2025)
            </Typography>
            <Performance />
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box>
            <Typography
              variant="body1"
              textAlign="center"
              fontSize={18}
              fontWeight="bold"
              className="mb-3"
            >
              Top 3 Bookings (2025)
            </Typography>
            <TopBookings />
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box>
            <Typography
              variant="body1"
              textAlign="center"
              fontSize={18}
              fontWeight="bold"
              className="mb-3"
            >
              Top 3 Cancellations (2025)
            </Typography>
            <TopCancellation />
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box>
            <Typography
              variant="body1"
              textAlign="center"
              fontSize={18}
              fontWeight="bold"
              className="mb-3"
            >
              Booking Summary (2025)
            </Typography>
            <BookingSummary />
          </Box>
        </Grid>
      </Grid>

      <Typography variant="body1" fontSize={18} fontWeight="bold" className="mb-3">
        Booking Calendar (2025)
      </Typography>
      <BookingCalender />
    </Box>
  );
}

export default Dashboard;
