import React from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import NoFoundTable from 'containers/common/components/NoFoundTable';
import useGetThemeColor from 'customHooks/useGetThemeColor';
import { tableCelStyle } from 'styles/mui/common/table-styles';
import { getColors } from '../utilities/data';

function BookingsDataTableBody({ analyticsData }) {
  const { tablePallete } = useGetThemeColor();
  if (analyticsData?.length === 0) {
    return <NoFoundTable message="No Booking Found" col={12} />;
  }
  return (
    <TableBody sx={{ ...tableCelStyle, ' & td': { padding: '10px', textAlign: 'center' }, }}>
      {analyticsData?.map(data => (
        <TableRow key={data.name} role="button" tabIndex="-1">
          <TableCell sx={{ backgroundColor: getColors(data.range) }}>
            {data.range}
          </TableCell>
          <TableCell
            sx={{
              borderRight: `1px solid ${tablePallete.borderClr}`,
              backgroundColor: getColors(data.range),
            }}
          >
            {data.name}
          </TableCell>
          <TableCell sx={{ backgroundColor: getColors(data.range) }}>
            {data.booking?.LY}
          </TableCell>
          <TableCell
            sx={{
              borderRight: `1px solid ${tablePallete.borderClr}`,
              backgroundColor: getColors(data.range),
            }}
          >
            {data.booking?.CY}
          </TableCell>
          <TableCell sx={{ backgroundColor: getColors(data.range) }}>
            {data.user_registration?.LY}
          </TableCell>
          <TableCell
            sx={{
              borderRight: `1px solid ${tablePallete.borderClr}`,
              backgroundColor: getColors(data.range),
            }}
          >
            {data.user_registration?.CY}
          </TableCell>
          <TableCell sx={{ backgroundColor: getColors(data.range) }}>
            {data.revenue?.LY}
          </TableCell>
          <TableCell
            sx={{
              borderRight: `1px solid ${tablePallete.borderClr}`,
              backgroundColor: getColors(data.range),
            }}
          >
            {data.revenue?.CY}
          </TableCell>

          <TableCell sx={{ backgroundColor: getColors(data.range) }}>
            {data.growth?.Amount}
          </TableCell>
          <TableCell sx={{ backgroundColor: getColors(data.range) }}>
            {data.growth?.percentage}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
}
BookingsDataTableBody.propTypes = {
  analyticsData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default BookingsDataTableBody;
