import React from 'react';
import styles from 'styles/common/card-pagination.module.scss';
import { Box, Pagination, Paper, Table, TableContainer } from '@mui/material';
import TableLoader from 'containers/common/components/TableLoader';
import usePaginationChange from 'customHooks/usePaginationChange';
import withTable from 'hoc/withTable';
import { useTableContext } from 'context/TableContext';
import { tableContainerStyle } from 'styles/mui/common/table-styles';
import HelpAndSupportTableHead from './HelpAndSupportTableHead';
import HelpAndSupportTableBody from './HelpAndSupportTableBody';

function HelpAndSupportTable() {
  const {
    isLoading,
    dataCount,
    // handleChangeRowsPerPage,
    rowsPerPage
  } = useTableContext();
  const { handleChangePagination, page: pageNumber } = usePaginationChange();
  return (
    <>
      <TableContainer className="mb-4" component={Paper} sx={tableContainerStyle}>
        <Table stickyHeader sx={{ width: '1700px', overflowX: 'auto' }}>
          <HelpAndSupportTableHead />
          {isLoading ? <TableLoader colSpan={12} /> : <HelpAndSupportTableBody />}
        </Table>

      </TableContainer>
      {dataCount > rowsPerPage && (
      <Box className={styles.pagination}>

        <Pagination
          color="primary"
          shape="rounded"
          count={dataCount ? Math.ceil((dataCount ?? 0) / rowsPerPage) : 1}
          page={pageNumber}
          onChange={(e, newPage) => handleChangePagination(newPage)}
        />
      </Box>
      )}
    </>

  );
}

export default withTable(HelpAndSupportTable);
