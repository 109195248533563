/* eslint-disable react/react-in-jsx-scope, react/jsx-filename-extension */
import {
  Dashboard,
  FlightTakeoffTwoTone,
  DirectionsCar,
  RestaurantMenu,
  Iso,
  Hotel,
  LocationCity
} from '@mui/icons-material';

export const DashboardItem = {
  title: 'Dashboard',
  path: '/',
  icon: <Dashboard />,
};
export const HelpAndSupportItem = [
  {
    title: 'Customer Service',
    path: '/help-and-support?is_admin_report=false',
  },
  { title: 'Technical Support',
    path: '/help-and-support?is_admin_report=true',
  }
];
export const HelpAndSupportItemTecTeam = [
  { title: 'Technical Support',
    path: '/help-and-support?is_admin_report=true',
  }
];
export const hotelsItem = {
  title: 'Manage Hotels',
  path: '/hotels',
  icon: <Hotel />,
};
export const citiesItem = {
  title: 'Manage Cities',
  path: '/cities',
  icon: <LocationCity />,
};
export const privateJetItem = {
  title: 'Private Jets',
  path: '/private-jets',
  icon: <FlightTakeoffTwoTone />,
};
export const limousineItem = {
  title: 'Limousine',
  path: '/limousine',
  icon: <DirectionsCar />,
};
export const restaurantItem = {
  title: 'Restaurant',
  path: '/restaurant',
  icon: <RestaurantMenu />,
};
export const BookingSubTabs = [
  {
    title: 'Upcoming ',
    path: '/bookings?upcoming=true',
  },
  {
    title: 'Previous ',
    path: '/bookings?previous_booking=true',
  },
  {
    title: 'Cancelled',
    path: '/bookings?status=cancelled',
  },
];

export const UsersSubTabs = [
  {
    title: 'Sublime Users ',
    path: '/users/sublime-users',
  },
  {
    title: 'Admin Users',
    path: '/users/admin-users',
  },
];

export const MarkupItem = {
  title: 'Agent Markup',
  path: '/markup-settings',
  icon: <Iso />,
};
