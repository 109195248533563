import React from 'react';
import PropTypes from 'prop-types';
import { Box, Pagination, Paper, Table, TableContainer } from '@mui/material';
import TableLoader from 'containers/common/components/TableLoader';
import usePaginationChange from 'customHooks/usePaginationChange';
import useGetParams from 'customHooks/useGetParams';
import withTable from 'hoc/withTable';
import { ROWS_PER_PAGE } from 'utilities/constants';
import { useGetAllUsersQuery } from 'services/private/users';
import { tableContainerStyle } from 'styles/mui/common/table-styles';
import UsersTableHead from './UsersTableHead';
import UsersTableBody from './UsersTableBody';

function UsersTable({ tableProps }) {
  const { handleRequestSort, order, orderBy } = tableProps;
  const { handleChangePagination, page: pageNumber } = usePaginationChange();
  const [apiParams] = useGetParams(10);
  const { data: allUsersData, isFetching } = useGetAllUsersQuery(apiParams);
  return (
    <TableContainer sx={tableContainerStyle} component={Paper}>
      <Table stickyHeader>
        <UsersTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={0}
        />
        {isFetching ? (
          <TableLoader />
        ) : (
          <UsersTableBody usersData={allUsersData?.results} />
        )}
      </Table>
      {allUsersData?.results?.length > 0 && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '20px',
          }}
          className="mb-3"
        >
          <Pagination
            color="primary"
            shape="rounded"
            count={
              allUsersData?.count
                ? Math.ceil((allUsersData?.count ?? 0) / ROWS_PER_PAGE)
                : 1
            }
            page={pageNumber}
            onChange={(e, newPage) => handleChangePagination(newPage)}
          />
        </Box>
      )}
    </TableContainer>
  );
}
UsersTable.propTypes = {
  tableProps: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withTable(UsersTable);
