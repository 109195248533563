import React, { useEffect, useState } from 'react';
import { TableBody, TableCell, TableRow, Chip } from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useCancelBookingMutation } from 'services/private/booking';
import { useNavigate } from 'react-router-dom';
import useHandleApiResponse from 'customHooks/useHandleApiResponse';
import NoFoundTable from 'containers/common/components/NoFoundTable';
// import { splitLongText } from 'utilities/helpers';
import CommonMenu from 'containers/common/components/CommonMenu';
import useGetParams from 'customHooks/useGetParams';
import { tableCelStyle } from 'styles/mui/common/table-styles';
import { Options } from '../utilities/data';

function BookingTableBody({ booking }) {
  const navigate = useNavigate();
  const [params] = useGetParams();
  const [actionOptions, setActionOptions] = useState(Options);

  const [CancelBooking, { error, isSuccess }] = useCancelBookingMutation();
  useHandleApiResponse(error, isSuccess, 'Booking Cancelled Successfully');

  const handleItemClicked = async (item, data) => {
    if (item.value === 'cancel') {
      await CancelBooking({ booking_confirmation_number: data.confirmation_number });
    } else {
      navigate(`/booking/detail/${data.id}`);
    }
  };

  useEffect(() => {
    setActionOptions(() => [
      ...Options,
      ...(params?.upcoming === 'true'
        ? [{ value: 'cancel', label: 'Cancel', selectedValue: 'cancel' }]
        : []),
    ]);
  }, [params]);
  if (booking?.length === 0) {
    return <NoFoundTable message="No Booking Found" col={12} />;
  }
  return (
    <TableBody sx={tableCelStyle}>
      {booking?.map(data => (
        <TableRow key={data.id} role="button" tabIndex="-1">
          <TableCell>{data.hotel_name || 'N/A'}</TableCell>
          <TableCell>
            {data.created_at && moment(data.created_at).format('D MMM YYYY')}
          </TableCell>
          <TableCell
            sx={{ ...tableCelStyle, fontWeight: 'bold' }}
            component="td"
            scope="row"
          >
            {data?.user_name || 'N/A'}
          </TableCell>
          <TableCell
            sx={{ ...tableCelStyle, fontWeight: 'bold' }}
            component="td"
            scope="row"
          >
            {data.sublime_booking_id || 'N/A'}
          </TableCell>
          <TableCell>
            {data.start_date && moment(data.start_date).format('D MMM YYYY')}
          </TableCell>
          <TableCell>
            {data.end_date && moment(data.end_date).format('D MMM YYYY')}
          </TableCell>
          <TableCell>
            {data.requested_currency_code || ''} {data.total_fare || 'N/A'}
          </TableCell>
          <TableCell>{data.total_rooms || 'N/A'}</TableCell>
          <TableCell>
            <Chip
              sx={{ textTransform: 'capitalize' }}
              label={data.booking_status}
              color={data.booking_status === 'confirmed' ? 'success' : 'error'}
            />
          </TableCell>

          <TableCell>
            <CommonMenu
              menus={actionOptions}
              func={handleItemClicked}
              data={data}
              isSimple
            />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
}
BookingTableBody.propTypes = {
  booking: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default BookingTableBody;
