import React, { useMemo } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Box } from '@mui/material';
import propTypes from 'prop-types';
import useGetThemeColor from 'customHooks/useGetThemeColor';

// Register necessary components
ChartJS.register(ArcElement, Tooltip, Legend);

function Locations({ locationsData }) {
  const { secondary, primaryLight, primary } = useGetThemeColor();
  const labelsData = useMemo(
    () => locationsData?.map(item => item.name),
    [locationsData]
  );

  const dataset = useMemo(() => locationsData?.map(item => item.count), [locationsData]);

  const data = {
    labels: labelsData,
    datasets: [
      {
        data: dataset,
        backgroundColor: [primary, primaryLight, secondary], // Matching colors from image
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'rect',
          boxWidth: 12, // Keeps legend small
          padding: 12,
        },
      },
    },
  };
  return (
    <Box
      sx={{
        width: '100%',
        height: '270px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Pie data={data} options={options} />
    </Box>
  );
}

Locations.propTypes = {
  locationsData: propTypes.array.isRequired,
};

export default Locations;
