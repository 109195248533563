/* eslint-disable consistent-return */
import { Analytics, Group, LocalAtm } from '@mui/icons-material';

export const analyticsCardsList = {
  label1: 'Amount With Tax',
  icon1: LocalAtm,
  label2: 'Bookings',
  icon2: Analytics,
  label3: 'Users',
  icon3: Group,
  label4: 'Amount Without Tax',
  icon4: LocalAtm,
};

export const bookingsDataTableHeadings = [
  {
    id: 'range',
    label: 'Range',
    border: false,
    disablePadding: true,
  },
  {
    id: 'name',
    label: 'Name',
    border: true,
    disablePadding: true,
  },
  {
    id: 'LY',
    label: 'LY',
    border: false,
    disablePadding: true,
  },
  {
    id: 'CY',
    label: 'CY',
    border: true,
    disablePadding: true,
  },
  {
    id: 'LY',
    label: 'LY',
    border: false,
    disablePadding: true,
  },
  {
    id: 'CY',
    label: 'CY',
    border: true,
    disablePadding: true,
  },
  {
    id: 'LY',
    label: 'LY',
    border: false,
    disablePadding: true,
  },
  {
    id: 'CY',
    label: 'CY',
    border: true,
    disablePadding: true,
  },
  {
    id: 'amount',
    label: 'Amount',
    border: false,
    disablePadding: true,
  },
  {
    id: 'percentage',
    label: '%',
    border: false,
    disablePadding: true,
  },
];

export const bookingsDataTableMainHeadings = [
  {
    id: 'labels',
    label: '',
    disablePadding: true,
  },
  {
    id: 'booking',
    label: 'Booking',
    disablePadding: true,
  },
  {
    id: 'user-registration',
    label: 'User Registration',
    disablePadding: true,
  },
  {
    id: 'revenue',
    label: 'Revenue',
    disablePadding: true,
  },
  {
    id: 'growth',
    label: 'Growth',
    disablePadding: true,
  },
];

export const getColors = range => {
  if (range === 'Today') return '#ECECEC';
  if (range === 'Yesterday') return '#E7E7E7';
  if (range === 'W2D') return '#DFDFDF';
  if (range === 'M2D') return '#D9D9D9';
  if (range === 'Y2D') return '#CFCFCF';

  return '#E7E7E7'; // Default color
};
