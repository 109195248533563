export const tableContainerStyle = {
  borderRadius: '15px',
};

export const tableStyle = {
  tableLayout: 'fixed',
  width: '100%',
};

export const tableCelStyle = {
  ' & td': { padding: '10px', textAlign: 'start' },
};

export const tableHeadStyles = {
  ' & th': { padding: '10px', textAlign: 'start' },
  '& .MuiTableCell-root': {
    position: 'static',
    minWidth: '100%'
  },
};
