import { useTheme } from '@mui/material';
import { useMemo } from 'react';

const useGetThemeColor = () => {
  const { palette } = useTheme();
  const primary = useMemo(() => palette.primary.main, [palette]);
  const primaryLight = useMemo(() => palette.primary.light, [palette]);
  const secondary = useMemo(() => palette.secondary.main, [palette]);
  const grayClr = useMemo(() => palette.greyColor.main, [palette]);
  const blackClr = useMemo(() => palette.blackClr.main, [palette]);
  const whiteClr = useMemo(() => palette.whiteClr.main, [palette]);
  const tablePallete = useMemo(() => palette.tablePallete, [palette]);

  return { primary, secondary, grayClr, blackClr, whiteClr, tablePallete, primaryLight };
};

export default useGetThemeColor;
