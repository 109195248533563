import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Link, useLocation, useMatch } from 'react-router-dom';
import { menuItemStyles, sidebarItemStyles } from 'styles/mui/containers/layoutStyles';
import useGetThemeColor from 'customHooks/useGetThemeColor';

function SidebarItem({ title, icon, path, isOpen, ismenu, type }) {
  const { pathname } = useLocation();
  const match = useMatch(path);

  const { blackClr } = useGetThemeColor();

  let isActive;
  if (path !== '/') {
    isActive = path !== '/' && pathname.includes(path);
  } else if (path === '/') {
    isActive = match;
  }

  return (
    <Box>
      <Box mb={type === 'lable' ? 1 : 0}>
        {type === 'lable' && !isOpen && (
          <Typography fontWeight={600} color="primary">{title}</Typography>
        )}
      </Box>

      {type === '' && (
        <Link to={path}>
          <ListItemButton
            className={`${isOpen || ismenu ? '' : 'px-2'} menu-item`}
            selected={!!isActive}
            sx={{
              ...(ismenu ? menuItemStyles : sidebarItemStyles),
              '&.Mui-selected, &.Mui-selected:hover, &:hover': { backgroundColor: blackClr },
            }}
          >
            {!ismenu && <ListItemIcon sx={{ color: 'black' }}>{icon}</ListItemIcon>}
            {!isOpen && <ListItemText primary={`${title}`} />}
          </ListItemButton>
        </Link>
      )}
      <Box mt={type === 'divider' ? 1 : 0} mb={type === 'divider' ? 1 : 0}>
        {type === 'divider' && !isOpen && (
          <Divider />
        )}
      </Box>
    </Box>
  );
}

SidebarItem.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.element,
  path: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  ismenu: PropTypes.bool,
  type: PropTypes.string,
};

SidebarItem.defaultProps = {
  title: 'Link',
  icon: null,
  path: '/',
  ismenu: false,
  type: '',
};

export default SidebarItem;
