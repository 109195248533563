import React from 'react';
import { Box, Typography } from '@mui/material';
import propTypes from 'prop-types';

function SummaryCard({ icon, title, value }) {
  return (
    <Box className="d-flex flex-column justify-content-between align-items-center gap-1 p-1">
      {icon}
      <Typography variant="body1" fontSize={18}>{title}</Typography>
      <Typography variant="body1" mt={1} fontSize={32} fontWeight={600}>
        {value}
      </Typography>
    </Box>
  );
}

SummaryCard.propTypes = {
  icon: propTypes.element.isRequired,
  title: propTypes.string.isRequired,
  value: propTypes.string.isRequired,
};

export default SummaryCard;
