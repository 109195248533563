import React from 'react';
import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material';
// import { useSelector } from 'react-redux';
import { useTableContext } from 'context/TableContext';
import { tableHeadStyles } from 'styles/mui/common/table-styles';
import useGetThemeColor from 'customHooks/useGetThemeColor';
import { helpAndSupportTableHeadings } from '../utilities/data';

function HelpAndSupportTableHead() {
  const { data, numSelected, handleSelectAllClick, order, orderBy, shouldShowButton } = useTableContext();
  // const { user: userInfo } = useSelector(state => state.auth);
  const { tablePallete, whiteClr } = useGetThemeColor();
  return (
    <TableHead sx={tableHeadStyles}>
      <TableRow>
        {shouldShowButton() && (
          <TableCell
            padding="none"
            sx={{
              backgroundColor: tablePallete.headBg,
              color: whiteClr
            }}
          >
            <Checkbox
              sx={{ color: whiteClr,
                p: 0,
                '&.Mui-checked': {
                  color: whiteClr,
                }, }}
              indeterminate={numSelected > 0 && numSelected < data.length}
              checked={numSelected === data.length}
              onChange={e => handleSelectAllClick(e, data, 'report_number')}
            />
          </TableCell>
        )}

        {helpAndSupportTableHeadings.map(row => (
          <TableCell
            key={row.id}
            align="left"
            className="fw-semibold"
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
            sx={{
              backgroundColor: tablePallete.headBg,
              color: whiteClr
            }}
          >
            {row.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default HelpAndSupportTableHead;
