import React from 'react';
import PropTypes from 'prop-types';
import { Box, Pagination, Paper, Table, TableContainer } from '@mui/material';
import TableLoader from 'containers/common/components/TableLoader';
import { useGetAllAdminUsersQuery } from 'services/private/adminusers';
import usePaginationChange from 'customHooks/usePaginationChange';
import useGetParams from 'customHooks/useGetParams';
import withTable from 'hoc/withTable';
import { ROWS_PER_PAGE } from 'utilities/constants';
import { tableContainerStyle } from 'styles/mui/common/table-styles';
import AdminUserTableBody from './AdminUserTableBody';
import AdminUserTableHead from './AdminUserTableHead';

function AdminUserTable({ tableProps }) {
  const { handleRequestSort, order, orderBy } = tableProps;
  const { handleChangePagination, page: pageNumber } = usePaginationChange();
  const [apiParams] = useGetParams(10);
  const { data: allAdminUsersData, isFetching } = useGetAllAdminUsersQuery(apiParams);
  return (
    <TableContainer sx={tableContainerStyle} component={Paper}>
      <Table stickyHeader>
        <AdminUserTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={0}
        />
        {isFetching ? (
          <TableLoader />
        ) : (
          <AdminUserTableBody adminData={allAdminUsersData?.results} />
        )}
      </Table>
      {allAdminUsersData?.results?.length > 0 && (
        <Box
          style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}
          className="mb-3"
        >
          <Pagination
            color="primary"
            shape="rounded"
            count={
              allAdminUsersData?.count
                ? Math.ceil((allAdminUsersData?.count ?? 0) / ROWS_PER_PAGE)
                : 1
            }
            page={pageNumber}
            onChange={(e, newPage) => handleChangePagination(newPage)}
          />
        </Box>
      )}
    </TableContainer>
  );
}
AdminUserTable.propTypes = {
  tableProps: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default withTable(AdminUserTable);
