import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import { containerBoxStyles } from 'styles/mui/containers/dashboard';
import { useGetTopBookingsMetricsQuery } from 'services/private/dashboard';
import Locations from './components/Locations';
import Hotels from './components/Hotels';
import Nationalities from './components/Nationalities';

function TopBookings() {
  const { data } = useGetTopBookingsMetricsQuery();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={4}>
        <Box sx={containerBoxStyles}>
          <Typography variant="body2" textAlign="center" className="mb-3">
            Locations
          </Typography>
          <Locations locationsData={data?.locations} />
        </Box>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Box sx={containerBoxStyles}>
          <Typography variant="body2" textAlign="center" className="mb-3">
            Hotels
          </Typography>
          <Hotels hotelsData={data?.hotels} />
        </Box>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Box sx={containerBoxStyles}>
          <Typography variant="body2" textAlign="center" className="mb-3">
            Nationalities
          </Typography>
          <Nationalities nationalitiesData={data?.nationalities} />
        </Box>
      </Grid>
    </Grid>
  );
}

export default TopBookings;
