import { privateAPI } from './index';

export const dashboardAPI = privateAPI.injectEndpoints({
  endpoints: build => ({
    getBookingsAnalytics: build.query({
      query: () => ({
        url: '/admin-dashboard/v1/dashboard_analytics/get_booking_analytics/',
        method: 'GET',
      }),
    }),
    getPerformanceMetrics: build.query({
      query: () => ({
        url: '/admin-dashboard/v1/dashboard_analytics/get_performance_metrics/',
        method: 'GET',
      }),
    }),
    getTopCancellationsMetrics: build.query({
      query: () => ({
        url: '/admin-dashboard/v1/dashboard_analytics/get_top_cancellations/',
        method: 'GET',
      }),
    }),
    getBookingSummaryMetrics: build.query({
      query: () => ({
        url: '/admin-dashboard/v1/dashboard_analytics/get_booking_summary/',
        method: 'GET',
      }),
    }),
    getTopBookingsMetrics: build.query({
      query: () => ({
        url: '/admin-dashboard/v1/dashboard_analytics/get_top_booking/',
        method: 'GET',
      }),
    }),
    getBookingCalenderMetrics: build.query({
      query: () => ({
        url: '/admin-dashboard/v1/dashboard_analytics/get_booking_calendar/',
        method: 'GET',
      }),
    }),
  }),
});
export const {
  useGetBookingsAnalyticsQuery,
  useGetPerformanceMetricsQuery,
  useGetTopBookingsMetricsQuery,
  useGetBookingSummaryMetricsQuery,
  useGetTopCancellationsMetricsQuery,
  useGetBookingCalenderMetricsQuery
} = dashboardAPI;
