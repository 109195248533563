import { privateAPI } from './index';

export const bookingAPI = privateAPI.injectEndpoints({
  endpoints: build => ({
    getBookingTypes: build.query({
      query: param => ({
        url: '/admin-dashboard/admin-booking-list/',
        method: 'GET',
        params: param,
        // params: {
        //   booking_status: param.status,
        //   future_booking: param.future,
        //   previous_booking: param.past,
        //   limit: param.limit,
        //   offset: param.offset,
        // },
      }),
      providesTags: ['Booking'],
    }),
    cancelBooking: build.mutation({
      query: body => ({
        url: '/admin-dashboard/admin-booking-cancel/',
        method: 'PUT',
        body: { confirmation_number: body.booking_confirmation_number },

      }),
      invalidatesTags: ['Booking'],
    }),
    getBookingDetail: build.query({
      query: body => ({
        url: `/admin-dashboard/admin-booking-retrieve/${body.bookingId}/`,
        method: 'GET',
      }),
    }),
  }),
});
export const {
  useGetBookingTypesQuery,
  useCancelBookingMutation,
  useGetBookingDetailQuery,
} = bookingAPI;
