/* eslint-disable no-unused-vars */
import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import GlobalLoader from 'containers/common/loaders/GlobalLoader';
import Dashboard from 'containers/pages/dashboard';
import HelpAndSupport from 'containers/pages/helpandsupport';
import AddUser from 'containers/pages/adduser';
import Booking from 'containers/pages/booking';
import Users from 'containers/pages/users';
import AdminUsers from 'containers/pages/adminusers';
import BookingDetailsPage from 'containers/pages/booking/detail';
import Chat from 'containers/pages/chat';
import TicketDetail from 'containers/pages/helpandsupport/detail';

import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';

const LogIn = lazy(() => import('containers/pages/auth/login'));
const ForgotPassword = lazy(() => import('containers/pages/auth/forgotPassword'));
const ChangePassword = lazy(() => import('containers/pages/auth/changePassword/index'));
const PrivateJets = lazy(() => import('containers/pages/privateJets'));
const Limousine = lazy(() => import('containers/pages/limousine'));
const Restaurant = lazy(() => import('containers/pages/restaurant'));
const PrivateJetsDetailPage = lazy(() => import('containers/pages/privateJets/detail'));
const LimousineDetailPage = lazy(() => import('containers/pages/limousine/detail'));
const RestaurantDetailPage = lazy(() => import('containers/pages/restaurant/detail'));

const UserProfile = lazy(() => import('containers/pages/user/profile'));

function Router() {
  return (
    <BrowserRouter>
      <Suspense fallback={<GlobalLoader isLoading />}>
        <Routes>
          <Route path="/auth" element={<PublicRoutes />}>
            <Route path="sign-in" element={<LogIn />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ChangePassword />} />
          </Route>

          <Route path="/" element={<PrivateRoutes />}>
            <Route index element={<Dashboard />} />
            <Route path="users" element={<Outlet />}>
              <Route path="sublime-users" element={<Users />} />
              <Route path="add-admin-user" element={<AddUser />} />
              <Route path="admin-users" element={<AdminUsers />} />
            </Route>
            <Route path="user/:id/profile/" element={<UserProfile />} />
            <Route path="/help-and-support" element={<HelpAndSupport />} />
            <Route path="/bookings" element={<Booking />} />
            <Route path="/booking/detail/:id" element={<BookingDetailsPage />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/ticket/details/:id" element={<TicketDetail />} />
            <Route path="/private-jets" element={<PrivateJets />} />
            <Route path="/limousine" element={<Limousine />} />
            <Route path="/restaurant" element={<Restaurant />} />
            <Route path="/private-jets-detail/:id" element={<PrivateJetsDetailPage />} />
            <Route path="/limousine-detail/:id" element={<LimousineDetailPage />} />
            <Route path="/restaurants-detail/:id" element={<RestaurantDetailPage />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default Router;
