import React from 'react';
import { Box, Grid } from '@mui/material';
import { Person, EventNote, Apartment, Person3 } from '@mui/icons-material';

import { summaryCardStyles } from 'styles/mui/containers/dashboard';
import { useGetBookingSummaryMetricsQuery } from 'services/private/dashboard';
import SummaryCard from './components/SummaryCard';

function BookingSummary() {
  const { data } = useGetBookingSummaryMetricsQuery();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={4}>
        <Box sx={summaryCardStyles}>
          <SummaryCard
            icon={<Person fontSize="large" />}
            title="Users"
            value={data?.data?.users}
          />
        </Box>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Box sx={summaryCardStyles}>
          <SummaryCard
            icon={<EventNote fontSize="large" />}
            title="Booking Days"
            value={data?.data?.booking_days}
          />
        </Box>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Box sx={summaryCardStyles}>
          <SummaryCard
            icon={<Apartment fontSize="large" />}
            title="Hotels"
            value={data?.data?.hotels}
          />
        </Box>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Box sx={summaryCardStyles}>
          <SummaryCard
            icon={<Person3 fontSize="large" />}
            title="Platinum Users"
            value={data?.data?.platinum_users}
          />
        </Box>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Box sx={summaryCardStyles}>
          <SummaryCard
            icon={<Person3 fontSize="large" />}
            title="Elite Users"
            value={data?.data?.elite_users}
          />
        </Box>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Box sx={summaryCardStyles}>
          <SummaryCard
            icon={<Person3 fontSize="large" />}
            title="Silver Users"
            value={data?.data?.silver_users}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default BookingSummary;
