import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow } from '@mui/material';
import useGetThemeColor from 'customHooks/useGetThemeColor';
import { tableHeadStyles } from 'styles/mui/common/table-styles';
import { bookingTableHeadings } from '../utilities/data';

function BookingTableHead({ order, orderBy }) {
  const { tablePallete, whiteClr } = useGetThemeColor();
  return (
    <TableHead sx={tableHeadStyles}>
      <TableRow>
        {bookingTableHeadings.map((row, index) => (
          <TableCell
            key={index}
            padding="none"
            sortDirection={orderBy === row.id ? order : false}
            className="fw-semibold"
            sx={{
              backgroundColor: tablePallete.headBg,
              color: whiteClr,
            }}
          >
            <div className={row.disablePadding ? '' : 'p-1'}>{row.label}</div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
BookingTableHead.propTypes = {
  // onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};
export default BookingTableHead;
