import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { HELP_AND_SUPPORT, OPERATIONS, IS_ADMIN, IS_STAFF, ADMIN, TecTeam, TecTeamHead, QATeam } from 'utilities/constants';

function useAuthInfo() {
  const { isAuthenticated } = useSelector(state => state.auth);

  const { user } = useSelector(state => state.auth);

  const profile = useMemo(() => user?.profile, [user]);
  const isSuperUser = useMemo(
    () => user?.user_type === IS_ADMIN && user?.profile?.department_name === ADMIN,
    [user]
  );
  const isHelpAndSupportHead = useMemo(
    () => user?.user_type === IS_ADMIN && user?.profile?.department_name === HELP_AND_SUPPORT,
    [user]
  );
  const isHelpAndSupportStaff = useMemo(
    () => user?.user_type === IS_STAFF && user?.profile?.department_name === HELP_AND_SUPPORT,
    [user]
  );
  const isOperationsHead = useMemo(
    () => user?.user_type === IS_ADMIN && user?.profile?.department_name === OPERATIONS,
    [user]
  );
  const isOperationsStaff = useMemo(
    () => user?.user_type === IS_STAFF && user?.profile?.department_name === OPERATIONS,
    [user]
  );
  const isTecTeamStaff = useMemo(
    () => user?.user_type === IS_ADMIN && user?.profile?.department_name === TecTeam,
    [user]
  );
  const isTecTeamHead = useMemo(
    () => user?.user_type === IS_ADMIN && user?.profile?.department_name === TecTeamHead,
    [user]
  );
  const isQaTeam = useMemo(
    () => user?.profile?.department_name === QATeam,
    [user]
  );
  const department = useMemo(() => user?.profile?.department, [user]);
  return {
    user,
    profile,
    department,
    isHelpAndSupportHead,
    isHelpAndSupportStaff,
    isAuthenticated,
    isSuperUser,
    isOperationsHead,
    isOperationsStaff,
    isTecTeamStaff,
    isTecTeamHead,
    isQaTeam
  };
}

export default useAuthInfo;
