import React, { useMemo } from 'react';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

// MUI COMPONENTS && ICONS
import Box from '@mui/material/Box';
import { containerBoxStyles } from 'styles/mui/containers/dashboard';
import { useGetBookingCalenderMetricsQuery } from 'services/private/dashboard';
import useGetThemeColor from 'customHooks/useGetThemeColor';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

function BookingCalender() {
  const { secondary, primaryLight, } = useGetThemeColor();
  const { data: bookingData } = useGetBookingCalenderMetricsQuery();
  const lastYear = useMemo(
    () => bookingData?.data?.map(item => item.last_year),
    [bookingData]
  );
  const currenYear = useMemo(
    () => bookingData?.data?.map(item => item.current_year),
    [bookingData]
  );
  const data = {
    labels: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    datasets: [
      {
        label: 'Last Year',
        data: lastYear,
        backgroundColor: secondary,
        barPercentage: 0.7,
        categoryPercentage: 0.6, // More spacing
      },
      {
        label: 'Current Year',
        data: currenYear,
        backgroundColor: primaryLight,
        barPercentage: 0.7,
        categoryPercentage: 0.6,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: 'bottom' },
      tooltip: { enabled: true },
    },
    scales: {
      x: {
        grid: { display: false },
      },
      y: {
        beginAtZero: true,
        max: 100,
        ticks: { stepSize: 20 },
      },
    },
  };

  return (
    <Box
      width="100%"
      height="300px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={containerBoxStyles}
    >
      <Box width="100%" height="100%">
        <Bar data={data} options={options} />
      </Box>
    </Box>
  );
}

export default BookingCalender;
