export const adminUsersTableHeadings = [
  {
    id: 'id',
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'first_name',
    disablePadding: true,
    label: 'First Name',
  },
  {
    id: 'last_name',
    disablePadding: true,
    label: 'Last Name',
  },
  {
    id: 'email',
    disablePadding: true,
    label: 'Email',
  },
  {
    id: 'phone_number',
    disablePadding: true,
    label: 'Phone Number',
  },
  {
    id: 'user_type',
    disablePadding: true,
    label: 'Role',
  },
  {
    id: 'department',
    disablePadding: true,
    label: 'Department',
  },
  {
    id: 'status',
    disablePadding: true,
    label: 'Status',
  },
];
export const test = {};
