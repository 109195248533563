import { palette } from '../theme';
import { muted } from '../themeVariables';

const navbarHeight = '60px';
const sidebarWidth = '270px';
const smallSidebarWidth = '58px';

const primary = palette.primary.main;

export const mainContainerStyles = isOpen => ({
  marginLeft: '30px',
  maxWidth: `calc(100% - ${smallSidebarWidth}) !important`,
  marginTop: '4rem',

  ...(!isOpen && {
    maxWidth: `calc(100% - ${sidebarWidth}) !important`,
    marginTop: '4rem',
    marginLeft: '15px!important',
  }),
});
export const listItemButtonStyles = {
  background: 'white',
  ':hover': { background: '#fff1e2' },
};

export const navbarStyles = isOpen => ({
  background: 'white',
  transition: '0.1s ease-in-out',
  top: 0,
  height: '60px',
  zIndex: '101',
  boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.05)',
  '& .navLogo': {
    width: '211px',
  },

  ...(!isOpen && {
    '& .listItemIcon': {
      minWidth: 'auto',
    },
  }),
});

export const sidebarContStyles = isOpen => ({
  paddingTop: '15px',
  height: '100%',
  background: 'white',
  width: sidebarWidth,
  maxWidth: sidebarWidth,
  position: 'fixed',
  left: 0,
  top: navbarHeight,
  bottom: 0,
  marginTop: 0,
  boxShadow: '0 6px 10px rgba(187, 187, 187, 0.5)',
  transition: '0.1s ease-in-out',
  ...(!isOpen && {
    width: smallSidebarWidth,
    maxWidth: smallSidebarWidth,

    '& .listItemIcon': {
      minWidth: 'auto',
    },
  }),
});

export const sidebarItemStyles = {
  marginBottom: '10px',
  borderRadius: '6px',
  background: '#e8eef5',
  '&.Mui-selected': {
    background: primary,
    color: 'white !important',

    '&:hover': {
      background: primary,
    },
  },
  ':hover': { background: primary },
};
export const menuItemStyles = {
  '& .MuiListItemText-root': {
    '& .MuiTypography-root': {
      fontSize: '13px !important', // Add font size to MuiListItemText-root
      lineHeight: '10px !important',
    },
  },
  minWidth: '10rem',
  '&.Mui-selected': {
    // background: primary,
    color: 'white',

    '&:hover': {
      // background: primary,
    },
  },
  ':hover': { background: primary },
};
export const sidebarListStyles = isOpen => ({
  '& .sidebarText': { display: isOpen ? 'block' : 'none' },
});

export const tooltipIconStyles = {
  fontSize: '17px',
  color: muted,
};

export const attendanceCard = {
  background: 'white',
  border: '3px solid #ECEEF6',
  borderRadius: '20px',
  height: '153px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};
export const attendanceCardIcons = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '7px',
  marginBottom: '18px',
  borderRadius: 50,
};

export const attendanceModalStyle = {
  width: '400px',
  padding: '20px',
};

export const chartContainerStyle = {
  padding: '5px 10px',
  borderRadius: '8px',
  backgroundColor: 'white',
  height: '320px',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 4px 12px 0px',
};

export const flexItemContainer = {
  flexBasis: '65%',
  display: 'flex',
  flexDirection: 'column',
  '@media screen and (max-width: 768px)': {
    flexBasis: '100%',
    width: '100%',
  },
};

export const flexItemBox = {
  padding: '20px',
  borderRadius: '8px',
  backgroundColor: 'white',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  '@media screen and (max-width: 768px)': {
    flexBasis: '100%',
    width: '100%',
  },
};

export const flexContainerStyle = {
  marginBottom: '18px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '18px',
  '@media screen and (max-width: 768px)': {
    flexDirection: 'column',
  },
};
export const notificationMenuStyles = {
  '& .MuiPaper-root': {
    minWidth: '400px',
    marginTop: '10px',
    borderRadius: '10px',
  },
};
