import React, { useMemo, useRef } from 'react';
import {
  Check,
  CheckCircleOutlineRounded,
  Cancel,
  Print,
} from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Grid, Rating, Stack, Typography, Button, Paper, CircularProgress } from '@mui/material';
import { useCancelBookingMutation, useGetBookingDetailQuery } from 'services/private/booking';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';

import GlobalLoader from 'containers/common/loaders/GlobalLoader';
import {
  formatAfterCancelationDate,
  formatCancelationDate,
  getParsedPrice,
} from 'utilities/helpers';
import useHandleApiResponse from 'customHooks/useHandleApiResponse';
import BookingGuestDetails from './components/BookingGuestDetails';

function BookingDetailsPage() {
  const contentRef = useRef();
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: bookingData, isLoading } = useGetBookingDetailQuery({ bookingId: id });
  const [cancelBooking, { error: cancelError, isLoading: isCancelLoading, isSuccess }] =
    useCancelBookingMutation();

  useHandleApiResponse(cancelError, isSuccess, 'Booking Cancelled Successful');

  const totalStayDays = useMemo(
    () => moment(bookingData?.end_date).diff(bookingData?.start_date, 'days'),
    [bookingData]
  );

  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
  });

  const handleCancelBooking = async supplierReferenceNumber => {
    cancelBooking(supplierReferenceNumber);
    navigate('/bookings?status=confirmed&future=true&past=false');
  };

  if (isLoading) {
    return <GlobalLoader isLoading />;
  }
  return (
    <Paper className="p-5" ref={contentRef} sx={{ minHeight: '100vh' }}>
      <Box className="d-flex justify-content-end pointer">
        <Box
          sx={{ background: '#E3E3E3', borderRadius: '6px', p: 1 }}
          onClick={handlePrint}
        >
          <Print sx={{ color: '#848484' }} />
        </Box>
      </Box>
      <Box className="d-flex flex-column align-items-center justify-content-center text-center gap-2 mb-5">
        {bookingData.booking_status === 'confirmed' ? (
          <CheckCircleOutlineRounded color="primary" sx={{ fontSize: 70 }} />
        ) : (
          <Cancel color="error" sx={{ fontSize: 70 }} />
        )}
        <Typography
          variant="body1"
          fontSize={24}
          textTransform="capitalize"
          fontWeight={500}
        >
          Booking {bookingData?.booking_status}
        </Typography>

        <Typography variant="body1" fontSize={18}>
          Sublime Booking ID: {bookingData?.sublime_booking_id}
        </Typography>
        <Typography variant="body1" fontSize={18}>
          Hotel Booking ID: {bookingData?.confirmation_number}
        </Typography>
      </Box>
      <Grid container className="d-flex justify-content-between">
        <Grid item xs={12} md={6} xl={5}>
          <Typography variant="pageTitle">{bookingData?.hotel_name}</Typography>
          <Box className="d-flex align-items-center gap-2 mb-4">
            <Typography>{bookingData?.hotel_address}</Typography>
            <Rating
              sx={{
                '& .MuiRating-iconFilled': {
                  color: '#D4B35B',
                },
                '& .MuiRating-icon': {
                  ml: 0.5,
                },
              }}
              name="read-only"
              value={5}
              precision={0.5}
              readOnly
              color="primary"
              size="small"
            />
          </Box>
          <Typography
            variant="body2"
            lineHeight={1.8}
            letterSpacing="1px"
            fontWeight={300}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
            nostrud Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam, quis nostrud
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} xl={5}>
          <BookingGuestDetails
            totalStayDays={totalStayDays}
            totalRooms={bookingData?.total_rooms}
            totalAdults={bookingData?.adult_count || 0}
            totalChilds={bookingData?.child_count || 0}
            startDate={bookingData?.start_date}
            endDate={bookingData?.end_date}
          />
        </Grid>
      </Grid>
      <Typography variant="pageTitle" className="mt-2 mb-4">
        Check in Detail
      </Typography>
      <Grid
        container
        spacing={2}
        rowSpacing={5}
        className="d-flex justify-content-between"
      >
        {bookingData?.customer_details?.map((room, index) => (
          <Grid key={index} item xs={12} md={6} xl={5}>
            <Typography fontWeight={500}>
              Room {index + 1}: Deluxe Room Double Bed With Burj Khalifa View
            </Typography>
            <Stack direction="row" spacing={2} my={2}>
              <Typography variant="body2" fontWeight={500}>
                {bookingData?.adult_count || 0} Adult
              </Typography>
              <Typography variant="body2" fontWeight={500}>
                {bookingData?.child_count || 0} Child
              </Typography>
              <Typography variant="body2" fontWeight={500}>
                {totalStayDays} Nights Stay
              </Typography>
            </Stack>
            <Stack direction="row" spacing={3} sx={{ color: 'gray' }}>
              <Box className="d-flex align-items-center gap-2">
                <Check fontSize="small" />
                <Typography variant="body2">Non Smoking</Typography>
              </Box>
              <Box className="d-flex align-items-center gap-2">
                <Check fontSize="small" />
                <Typography variant="body2">Free Parking</Typography>
              </Box>
              <Box className="d-flex align-items-center gap-2">
                <Check fontSize="small" />
                <Typography variant="body2">Free Wifi</Typography>
              </Box>
            </Stack>
            <Stack direction="column" spacing={3} mt={3}>
              {bookingData?.guest?.[index]?.map(guest => (
                <Box className="d-flex align-items-center">
                  <Typography variant="body2" fontWeight={500} width={150}>
                    Adult Name:
                  </Typography>
                  <Typography variant="body2" color="gray">
                    {guest?.first_name} {guest?.surname}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ width: { xs: '100%', md: '40%' } }} className="mt-5 mb-4">
        <Typography variant="pageTitle">Your Price Summary</Typography>
        <Box className="d-flex justify-content-between">
          <Typography variant="body2" fontWeight={500}>
            Total Price
          </Typography>
          <Box>
            <Typography variant="body2">{`AED ${bookingData?.total_after_tax}`}</Typography>
            <Typography variant="body2">
              +{bookingData?.requested_currency_code}{' '}
              {getParsedPrice(bookingData.total_after_tax - bookingData.room_rate)} taxes
              and fees
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className="d-flex flex-column gap-2 mt-5">
        <Typography variant="body1" fontWeight={500}>
          Payment Method
        </Typography>
        <Typography variant="body2" lineHeight={1.8} letterSpacing="1px">
          You have now confirmed and guaranteed your reservation by credit cards. All
          payments are to be made at the property during your stay, unless otherwise
          stated in the policies. The hotel reserves the right to pre-authorize credit
          cards prior to arrival.
        </Typography>
      </Box>
      <Box className="d-flex flex-column gap-2 my-5">
        <Typography variant="body1" fontWeight={500}>
          This Property accepts the following forms of payment:
        </Typography>
        <Typography variant="body2" lineHeight={1.8} letterSpacing="1px">
          American Express, Visa, Euro/Mastercard, Diners Club
        </Typography>
      </Box>
      {bookingData?.special_request && (
        <Box className="d-flex flex-column gap-2">
          <Typography variant="body1" fontWeight={500}>
            Special Request
          </Typography>
          <Typography variant="body2" lineHeight={1.8} letterSpacing="1px">
            {bookingData?.special_request}
          </Typography>
        </Box>
      )}
      {bookingData?.hotel_booking?.absolute_deadline && (
        <Box className="d-flex flex-column gap-2 mt-5">
          <Typography variant="body1" fontWeight={500}>
            Cancellation Policy:
          </Typography>
          <Typography variant="body2" lineHeight={1.8} letterSpacing="1px">
            Cancel free of cost upto{' '}
            {formatCancelationDate(bookingData?.hotel_booking?.absolute_deadline)}{' '}
            {bookingData?.hotel_booking?.absolute_deadline?.includes('Z')
              ? '(GMT)'
              : `(${bookingData?.hotel_booking?.city_name})`}
            . Post that you will be charged{' '}
            {`${bookingData?.requested_currency_code} ${bookingData?.amount}` ||
              `${bookingData?.percent} %`}{' '}
            {!bookingData?.amount && !bookingData?.percent && bookingData?.no_of_nights
              ? `for ${bookingData?.no_of_nights} nights`
              : ''}
          </Typography>
        </Box>
      )}
      <Box className="d-flex flex-column mt-5 gap-2">
        <Typography variant="body1" fontWeight={500}>
          Cancellation Cost:
        </Typography>
        <Typography variant="body2" lineHeight={1.8} letterSpacing="1px">
          Until {formatCancelationDate(bookingData?.hotel_booking?.absolute_deadline)}
          {bookingData?.hotel_booking?.absolute_deadline &&
          bookingData?.hotel_booking?.absolute_deadline?.includes('Z')
            ? '(GMT)'
            : `(${bookingData?.hotel_booking?.city_name})`}
          : 0
        </Typography>
        <Typography variant="body2" lineHeight={1.8} letterSpacing="1px">
          From {formatAfterCancelationDate(bookingData?.hotel_booking?.absolute_deadline)}{' '}
          {bookingData?.hotel_booking?.absolute_deadline?.includes('Z')
            ? '(GMT)'
            : `(${bookingData?.hotel_booking?.city_name})`}
          :{' '}
          {bookingData?.amount
            ? `${bookingData?.requested_currency_code} ${bookingData?.amount}`
            : bookingData?.percent}
          {!bookingData?.amount && !bookingData?.percent && bookingData?.no_of_nights
            ? `You will be charged for ${bookingData?.no_of_nights} nights`
            : ''}
        </Typography>
      </Box>
      {bookingData.booking_status === 'confirmed' && (
        <Box className="d-flex gap-3 my-5">
          <Button
            variant="contained"
            color="primary"
            startIcon={isCancelLoading ? <CircularProgress size={20} /> : undefined}
            disabled={isCancelLoading || moment(bookingData.start_date) < moment()}
            onClick={() => {
              handleCancelBooking(
                bookingData?.supplier_reference_number
              );
            }}
          >
            {isLoading ? 'Loading...' : 'Cancel booking'}
          </Button>
        </Box>
      )}
    </Paper>
  );
}

export default BookingDetailsPage;
