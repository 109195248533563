import React, { useState } from 'react';
import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { ArrowBack, Layers, Logout } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { sidebarItemStyles } from 'styles/mui/containers/layoutStyles';
import useGetThemeColor from 'customHooks/useGetThemeColor';
import { onLoggedOut } from 'store/slices/authSlice';
import { enqueueSnackbar } from 'notistack';
import { useLogoutMutation } from 'services/private/auth';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SidebarNested from './SidebarNested';
import SidebarItem from './SidebarItem';
import useGetSidebarLinks from './customHooks/useGetSidebarLinks';

function SidebarContent({ isOpen }) {
  const [isSubSidebarOpen, setIsSubSidebarOpen] = useState(false);
  const [section, setSection] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    superUserSidebar,
    settingMenus,
    subSidebarLinks,
    isMiscellaneousSection,
    isSettingsSection,
  } = useGetSidebarLinks();

  const { blackClr } = useGetThemeColor();

  const [logout] = useLogoutMutation();

  const toggleSubSidebar = val => {
    setSection(val);
    setIsSubSidebarOpen(prev => !prev);
  };

  const logoutFun = async () => {
    try {
      await logout();
      dispatch(onLoggedOut());
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
    }
  };

  return (
    <List
      className={`sidebar pt-4 pb-2 ${isOpen ? 'px-3' : 'px-4'}`}
      sx={{
        width: isOpen ? '90px' : '240px',
        boxShadow: '2px 0 2px 0 rgba(0, 0, 0, 0.1)',
        backgroundColor: '#f0f0f0',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {isSubSidebarOpen ? (
        <>
          <ListItemButton
            sx={{
              '&:hover': { backgroundColor: blackClr },
            }}
            className="mb-2"
            onClick={() => toggleSubSidebar('')}
          >
            <ListItemIcon>
              <ArrowBack />
            </ListItemIcon>
            {!isOpen && <ListItemText primary="Back" />}
          </ListItemButton>
          {section === 'isMiscellaneous' ? (
            <>
              {' '}
              {subSidebarLinks.map(link => (
                <SidebarItem
                  type={link?.type}
                  icon={link?.icon}
                  title={link?.title}
                  path={link?.path}
                  key={link?.path}
                  isOpen={isOpen}
                />
              ))}
            </>
          ) : (
            <>
              {' '}
              {settingMenus[0]?.data?.map(link => (
                <SidebarItem
                  type={link?.type}
                  icon={link?.icon}
                  title={link?.title}
                  path={link?.path}
                  key={link?.path}
                  isOpen={isOpen}
                />
              ))}
            </>
          )}
        </>
      ) : (
        superUserSidebar.map(link => {
          if (link?.data) {
            return (
              <SidebarNested
                title={link?.title}
                icon={link?.icon}
                data={link?.data}
                isOpen={isOpen}
              />
            );
          }
          return (
            <SidebarItem
              type={link?.type}
              icon={link?.icon}
              title={link?.title}
              path={link?.path}
              key={link?.path}
              isOpen={isOpen}
            />
          );
        })
      )}

      {!isSubSidebarOpen && (
        <Box sx={{ marginTop: 'auto' }}>
          <Divider sx={{ mb: 1 }} />
            {isMiscellaneousSection && (
              <ListItemButton
                onClick={() => toggleSubSidebar('isMiscellaneous')}
                sx={{
                  ...sidebarItemStyles,
                  '&.Mui-selected, &.Mui-selected:hover, &:hover': {
                    backgroundColor: blackClr,
                  },
                }}
                className={`${isOpen ? '' : 'px-2'} menu-item`}
              >
                <ListItemIcon sx={{ color: 'black' }}>
                  <Layers />
                </ListItemIcon>
                {!isOpen && <ListItemText primary="Miscellaneous" />}
              </ListItemButton>
            )}
            {isSettingsSection.map(link => {
              if (link?.data) {
                return (
                  <SidebarNested
                    title={link?.title}
                    icon={link?.icon}
                    data={link?.data}
                    isOpen={isOpen}
                  />
                );
              }
              return (
                <SidebarItem
                  type={link?.type}
                  icon={link?.icon}
                  title={link?.title}
                  path={link?.path}
                  key={link?.path}
                  isOpen={isOpen}
                />
              );
            })}
          <ListItemButton
            onClick={() => {
              logoutFun();
              window.location.reload();
              navigate('/auth/sign-in');
            }}
            sx={{
              ...sidebarItemStyles,
              '&.Mui-selected, &.Mui-selected:hover, &:hover': {
                backgroundColor: blackClr,
              },
            }}
            className={`${isOpen ? '' : 'px-2'} menu-item`}
          >
            <ListItemIcon sx={{ color: 'black' }}>
              <Logout />
            </ListItemIcon>
            {!isOpen && <ListItemText primary="Logout" />}
          </ListItemButton>
        </Box>
      )}
    </List>
  );
}
export default SidebarContent;
SidebarContent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};
